import { useBaseAxiosGetRequest, useBaseAxiosPostRequest } from "app";
import { SpecOption } from "assets";
import { TemplateRecommendation } from "records";
import { TutorialNode } from "tutorials/bs/models";
import {
  AppSettings,
  DataViewResponse,
  EnumValueOptions,
  FeatureFlag,
  KnownModelType,
  MetaFieldDefinition,
  QueryDataRequest,
  SubmitFeedbackRequest,
} from "./models";

export const useAppSettingsRequest = () => {
  const request = useBaseAxiosGetRequest<AppSettings>("/api/resources/appSettings", {
    axios: { useCache: true },
    useNode: true,
  });
  return request;
};

export const useFeatureFlagsRequest = () => {
  const request = useBaseAxiosGetRequest<FeatureFlag[]>("/api/resources/featureFlags", {
    axios: { useCache: true },
    useNode: true,
  });
  return request;
};

export const useGetSpecOptionsRequest = (type: string, manual?: boolean, dontUseCache?: boolean) =>
  useBaseAxiosGetRequest<string[]>(`api/resources/specOptions/${type}`, {
    axios: {
      manual: !!manual,
      useCache: !dontUseCache,
    },
    useNode: true,
  });

export const useGetAllSpecOptionsRequest = () =>
  useBaseAxiosGetRequest<SpecOption[]>(`api/resources/specOptions`, {
    axios: { useCache: true },
    useNode: true,
  });

export const useGetTemplateRecommendationsRequest = () =>
  useBaseAxiosGetRequest<TemplateRecommendation[]>(`api/resources/templateRecommendations`, {
    axios: { useCache: true },
    useNode: true,
  });

export const useGetTutorialsRequest = () =>
  useBaseAxiosGetRequest<TutorialNode[]>(`api/resources/tutorials`, {
    axios: { useCache: true },
    useNode: true,
  });

export const useQueryDataRequest = <T>() => useBaseAxiosPostRequest<QueryDataRequest, DataViewResponse<T>>(`api/data`);

export const useGetEnumValueOptionsRequest = () =>
  useBaseAxiosGetRequest<EnumValueOptions>(`api/data/enumValueOptions`, { axios: { useCache: true }, useNode: true });

export const useGetMetadataColumnsRequest = (modelType: KnownModelType, siteIds: number[]) =>
  useBaseAxiosGetRequest<MetaFieldDefinition[]>(
    !siteIds.length ? null : `api/data/types/${modelType}?${siteIds.map((siteId) => "siteIds=" + siteId).join("&")}`,
    {
      axios: { useCache: true },
      useNode: true,
    }
  );

export const useSubmitFeedbackRequest = () =>
  useBaseAxiosPostRequest<SubmitFeedbackRequest>(`api/feedback`, {
    useNode: true,
  });
export const useSubmitFeedbackWithAttachmentsRequest = () =>
  useBaseAxiosPostRequest<FormData>(`api/feedback/attachments`, {
    useNode: true,
  });
