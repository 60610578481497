import { Box, Stack, useTheme } from "@mui/material";
import { DiagnosticType } from "records/models/diagnosticType";

type BadgeVariant = "outlined" | "simple";

interface Props {
  diagnosticType?: DiagnosticType;
  isDarkTheme?: boolean;
  variant?: BadgeVariant;
}

export const DiagnosticTypeBadge = (props: Props) => {
  const { diagnosticType, variant = "outlined", isDarkTheme } = props;
  const theme = useTheme();
  const fill = !!isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main;

  if (diagnosticType == null) {
    return <></>;
  }

  return (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        border: variant === "outlined" ? `1px solid ${fill}` : "none",
        padding: variant === "outlined" ? "0.1rem 0.5rem" : "0",
        fontSize: variant === "outlined" ? ".8rem" : "1rem",
        borderRadius: "5px",
        color: fill,
        alignItems: "center",
      }}
    >
      <Box flex="1">{diagnosticType}</Box>
    </Stack>
  );
};
