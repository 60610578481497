import { TimeUnit } from "common";
import { KpiCommodityUnit } from ".";
import { KpiBase, KpiBaseFields } from "./KpiBase";
import { UseFormWatch } from "react-hook-form";

export interface KpiOngoingMaintenanceSavingsOrProductionImprovement extends KpiBase {
  spartanHours: number | null;
  spartanRatePerHour: number | null;
  remoteHours: number;
  customerHours: number | null;
  customerRatePerHour: number | null;
  equipmentAndMaterialsCost: number | null;
  calculatedCostToImplement: number | null;
  maintenanceSavingsIsOngoing: boolean | null;
  maintenanceCostTimeUnit: TimeUnit | null;
  maintenanceCostBeforeImplementation: number | null;
  maintenanceCostAfterImplementation: number | null;
  calculatedMaintenanceAnnualSavings: number | null;
  productionImprovementIsOngoing: boolean | null;
  commodityUnit: KpiCommodityUnit | null;
  commodityType: string | null;
  commodityProductionRateTimeUnit: TimeUnit | null;
  commodityPricePerUnit: number | null;
  productionRateBeforeImplementation: number | null;
  productionRateAfterImplementation: number | null;
  calculatedProductionIncrease: number | null;
  rateEffectiveDate: Date | null;
  rateEffectiveDateType: string | null;
  isSimplifiedVersion: boolean;
  isSimplifiedVersionLocked: boolean;
  migratedKpiLegacyId: number | null;
  simplifiedCostToImplement: number | null;
  simplifiedMaintenanceSavings: number | null;
  simplifiedProductionImprovement: number | null;
}

export const KpiOngoingMaintenanceSavingsOrProductionImprovementFields: (keyof KpiOngoingMaintenanceSavingsOrProductionImprovement)[] =
  [
    ...KpiBaseFields,
    "spartanHours",
    "spartanRatePerHour",
    "remoteHours",
    "customerHours",
    "customerRatePerHour",
    "equipmentAndMaterialsCost",
    "calculatedCostToImplement",
    "maintenanceSavingsIsOngoing",
    "maintenanceCostTimeUnit",
    "maintenanceCostBeforeImplementation",
    "maintenanceCostAfterImplementation",
    "calculatedMaintenanceAnnualSavings",
    "productionImprovementIsOngoing",
    "commodityUnit",
    "commodityType",
    "commodityProductionRateTimeUnit",
    "commodityPricePerUnit",
    "productionRateBeforeImplementation",
    "productionRateAfterImplementation",
    "calculatedProductionIncrease",
    "isSimplifiedVersion",
    "migratedKpiLegacyId",
    "simplifiedCostToImplement",
    "simplifiedMaintenanceSavings",
    "simplifiedProductionImprovement",
  ];

export class KpiOngoingMaintenanceSavingsOrProductionImprovementViewModel {
  model: KpiOngoingMaintenanceSavingsOrProductionImprovement;
  watch: UseFormWatch<Partial<KpiOngoingMaintenanceSavingsOrProductionImprovement>>;

  constructor(
    kpiValueDetail: KpiOngoingMaintenanceSavingsOrProductionImprovement,
    watch: UseFormWatch<Partial<KpiOngoingMaintenanceSavingsOrProductionImprovement>>
  ) {
    this.model = kpiValueDetail;
    this.watch = watch;
  }

  get isSimplifiedVersionValue(): boolean {
    return this.watch("isSimplifiedVersion") ?? false;
  }
  get isSimplifiedVersionLockedValue(): boolean {
    return this.watch("migratedKpiLegacyId") != null;
  }
  get customerHoursFieldValue(): number {
    return this.watch("customerHours") ?? 0;
  }
  get customerRatePerHourFieldValue(): number {
    return this.watch("customerRatePerHour") ?? 0;
  }
  get spartanHoursFieldValue(): number {
    return this.watch("spartanHours") ?? 0;
  }
  get spartanRatePerHourFieldValue(): number {
    return this.watch("spartanRatePerHour") ?? 0;
  }
  get maintenanceSavingsIsOngoingValue(): boolean {
    return this.watch("maintenanceSavingsIsOngoing") ?? false;
  }
  get productionImprovementIsOngoingValue(): boolean {
    return this.watch("productionImprovementIsOngoing") ?? false;
  }

  get calculatedMaintenanceAnnualSavingsFieldValue() {
    return this.model.calculatedMaintenanceAnnualSavings;
  }

  get calculatedCostToImplementFieldValue() {
    return this.model.calculatedCostToImplement;
  }
}
