import { Grid } from "@mui/material";
import { GetKpiCommodityUnitsForEmissions } from "kpis/models";
import {
  KpiOngoingEmissionsReduction,
  KpiOngoingEmissionsReductionViewModel,
} from "kpis/models/KpiOngoingEmissionsReduction";
import { useMemo } from "react";
import { EditKpiFormProps } from "../EditKpiFormProps";
import { KpiGridStyles } from "../KpiGridStyles";
import ProductionRateFormItem from "../formItems/ProductionRateFormItem";
import { KpiCommentsFormItem } from "../formItems/KpiCommentsFormItem";

interface KpiOngoingEmissionsReductionDetailsProps extends EditKpiFormProps {}

function KpiOngoingEmissionsReductionDetails(props: KpiOngoingEmissionsReductionDetailsProps) {
  const kpi = useMemo(
    () => new KpiOngoingEmissionsReductionViewModel(props.detail.model as KpiOngoingEmissionsReduction),
    [props.detail.model]
  );
  const hasTemplateComments = !!props.model.kpiTemplateId && !!props.model.templateComments;

  const { gridRowProps, gridContainerProps, kpiSectionProps, secondaryDividerTitleProps } = KpiGridStyles;

  const inputProps = useMemo(() => ({ readOnly: props.readOnly }), [props.readOnly]);

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Grid container {...gridContainerProps}>
          <Grid item xs={12}>
            <ProductionRateFormItem
              label="Baseline emissions (quantity/unit/product/rate)"
              quantityFieldName="amountBeforeReduction"
              unitFieldName="reductionUnit"
              productFieldName="reductionType"
              rateFieldName="reductionTimeUnit"
              units={GetKpiCommodityUnitsForEmissions(kpi.model.reductionType)}
              productionRateProps={{ mode: "Per" }}
              productTypeInputControl={"emissions"}
              {...inputProps}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductionRateFormItem
              label="As-left emissions (quantity/unit/product/rate)"
              quantityFieldName="amountAfterReduction"
              unitFieldName="reductionUnit"
              productFieldName="reductionType"
              rateFieldName="reductionTimeUnit"
              units={GetKpiCommodityUnitsForEmissions(kpi.model.reductionType)}
              productionRateProps={{ mode: "Per" }}
              productTypeInputControl={"emissions"}
              quantityOnly={true}
              {...inputProps}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={2}>
          {hasTemplateComments && (
            <Grid item xs={12} >
              <KpiCommentsFormItem
                label="Comments" 
                fieldName="templateComments"
                alwaysShowInput
                readOnly={true} 
              />
            </Grid>
          )}
          <Grid item xs={12} >
            <KpiCommentsFormItem
              label="Additional Comments" 
              fieldName="comments" 
              readOnly={!props.userCanEdit} 
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default KpiOngoingEmissionsReductionDetails;
