import {
   Box,
   Paper,
   Stack,
} from "@mui/material";

export const ImportOfflinePage = () => {

   return (
      <Box sx={{ width: "100%" }}>
         <Stack spacing={2}>
            <Paper sx={{ width: "100%", minWidth: 680, p: 2 }}>
            </Paper>
         </Stack>
      </Box>
   );
};
