import { getKpiCategory } from "./KpiSavingsCategory";
import { KpiValueModel } from "./models";

// Follows the desired ordering of the types
export type TemplateKpiType =
  | "OneTimeLabourOrEquipmentCostSavings"
  | "OneTimeDowntimeAvoidance"
  | "OneTimeReleaseOrSpillAvoidance"
  | "OneTimeScheduleDelayReduction"
  | "OngoingEmissionsReduction"
  | "OngoingMaintenanceSavingsOrProductionImprovement";
export type ValueKpiType = "None" | "Legacy" | TemplateKpiType;

export interface KpiTypeOption {
  value: TemplateKpiType;
  label: string;
  category: string;
}

export const getKpiTypeOption = (kpiType: TemplateKpiType): KpiTypeOption => {
  return { value: kpiType, label: toKpiTypeDiplayName(kpiType), category: getKpiCategory(kpiType) };
};

// Follows the desired ordering of the types
export const TemplateKpiTypes: TemplateKpiType[] = [
  "OneTimeLabourOrEquipmentCostSavings",
  "OneTimeDowntimeAvoidance",
  "OneTimeReleaseOrSpillAvoidance",
  "OneTimeScheduleDelayReduction",
  "OngoingEmissionsReduction",
  "OngoingMaintenanceSavingsOrProductionImprovement",
];

export const toKpiTypeDiplayName = (kpiType: ValueKpiType, compact?: boolean): string => {
  switch (kpiType) {
    case "None":
      return "";
    case "Legacy":
      return "Legacy";
    case "OneTimeDowntimeAvoidance":
      return "One-time downtime avoidance";
    case "OneTimeLabourOrEquipmentCostSavings":
      return compact ? "One-time savings" : "One-time labour or equipment cost savings";
    case "OneTimeReleaseOrSpillAvoidance":
      return "One-time release or spill avoidance";
    case "OneTimeScheduleDelayReduction":
      return "One-time schedule delay reduction";
    case "OngoingEmissionsReduction":
      return "Ongoing emissions reduction";
    case "OngoingMaintenanceSavingsOrProductionImprovement":
      return compact ? "Ongoing savings" : "Ongoing maintenance savings or production improvement";
  }
};

export const getKpiValueFullDisplayName = (kpiValue: KpiValueModel): string => {
  const templateNamePart = !!kpiValue.kpiTemplateId ? ` - ${kpiValue.name}` : "";

  return toKpiTypeDiplayName(kpiValue.kpiType) + templateNamePart;
};

export const ValidValueKpiTypes: ValueKpiType[] = ["Legacy", ...TemplateKpiTypes];

export const ValueKpiTypeMapping = ValidValueKpiTypes.map(
  (kpiType) => [kpiType, toKpiTypeDiplayName(kpiType)] as [ValueKpiType, string]
);

export const parseValueKpiTypeFromDisplayName = (value?: string | null) => {
  if (!!value) {
    const selected = ValueKpiTypeMapping.filter((v) => v[1].toLowerCase() === value.toLowerCase());
    if (selected.length > 0) {
      return selected[0][0];
    }
  }
  return "None";
};
export const orderByValueKpiType = (a: { kpiType: ValueKpiType }) => ValidValueKpiTypes.indexOf(a.kpiType);
