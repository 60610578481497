import React, { useState } from "react";
import { Box, Paper, Stack, Typography, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { UseFormReturn } from "react-hook-form";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { SummaryDetail } from "summaries/models";
import { useUserContext } from "app";
import { EditFieldDialog } from "app/mui/common/dialogs/EditFieldDialog";

interface SummaryDetailNotesProps {
  summaryDetail: SummaryDetail;
  methods: UseFormReturn<any>;
  saveChanges: () => void;
}

export const SummaryNotes: React.FC<SummaryDetailNotesProps> = ({ summaryDetail, methods, saveChanges }) => {
  const { userCanEditSummary } = useUserContext().userPermissions;
  const isClosed = summaryDetail.model.status === "Closed";
  const summary = summaryDetail.model;

  const [editingField, setEditingField] = useState<null | string>(null);

  const handleEditClick = (fieldName: string) => {
    setEditingField(fieldName);
  };

  const handleSaveLabel = (newLabel: string) => {
    if (editingField) {
      methods.setValue(editingField, newLabel);
      setEditingField(null);
    }
  };

  return (
    <Paper>
      <ExpansionPanel title="NOTES" defaultExpanded={true}>
        <Box>
          <Stack spacing={2} direction="column" alignItems="flex-start" sx={{ p: 2, width: "100%" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                {summary.highlightsLabel}
              </Typography>
              {userCanEditSummary && !isClosed && (
                <IconButton size="small" onClick={() => handleEditClick("highlightsLabel")}>
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
            <RichTextEditor fieldName={"highlights"} label={undefined} disabled={!userCanEditSummary || isClosed} />
          </Stack>

          <Stack spacing={2} direction="column" alignItems="flex-start" sx={{ p: 2, width: "100%" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                {summary.challengesLabel}
              </Typography>
              {userCanEditSummary && !isClosed && (
                <IconButton size="small" onClick={() => handleEditClick("challengesLabel")}>
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
            <RichTextEditor fieldName={"challenges"} label={undefined} disabled={!userCanEditSummary || isClosed} />
          </Stack>

          <Stack spacing={2} direction="column" alignItems="flex-start" sx={{ p: 2, width: "100%" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                {summary.generalRecommendationLabel}
              </Typography>
              {userCanEditSummary && !isClosed && (
                <IconButton size="small" onClick={() => handleEditClick("generalRecommendationLabel")}>
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
            <RichTextEditor
              fieldName={"generalRecommendations"}
              label={undefined}
              disabled={!userCanEditSummary || isClosed}
            />
          </Stack>

          <Stack spacing={2} direction="column" alignItems="flex-start" sx={{ p: 2, width: "100%" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                {summary.pathForwardLabel}
              </Typography>
              {userCanEditSummary && !isClosed && (
                <IconButton size="small" onClick={() => handleEditClick("pathForwardLabel")}>
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
            <RichTextEditor fieldName={"pathForward"} label={undefined} disabled={!userCanEditSummary || isClosed} />
          </Stack>
        </Box>
      </ExpansionPanel>

      {editingField && (
        <EditFieldDialog
          fieldName={editingField}
          currentValue={methods.getValues(editingField)}
          methods={methods}
          saveChanges={() => handleSaveLabel(methods.getValues(editingField))}
          onClose={() => setEditingField(null)}
          dialogTitle="Edit Label"
          label="Label"
          confirmButtonText="Edit"
        />
      )}
    </Paper>
  );
};
