import { GridFilterItem, GridLinkOperator, GridSortItem } from "@mui/x-data-grid";
import { useFeatureFlagsContext } from "app/contexts/FeatureFlagsContext";

export interface AppSettings {
  disablePdfGeneration: boolean;
  irisReportsUrl: string;
  irisLogoDesktopUrlDark: string;
  irisLogoMobileUrlDark: string;
  irisLogoDesktopUrlLight: string;
  irisLogoMobileUrlLight: string;
  irisFavIconUrl: string;
}

export interface FeatureFlag {
  featureName: string;
  isEnabled: boolean;
}

export enum FeatureFlagName {
  UseEventsLayoutV2 = "UseEventsLayoutV2",
  UseAssetTypeListPages = "UseAssetTypeListPages",
  UseSummariesLayoutV2 = "UseSummariesLayoutV2",
  UseFeedbackWithAttachments = "UseFeedbackWithAttachments",
  UseNodeReports = "UseNodeReports",
  UseNodeRecordReport = "UseNodeRecordReport",
  UseFieldInspectionSummary = "UseFieldInspectionSummary",
  UseMachineryComponents = "UseMachineryComponents",
  UseMachinerySpecs = "UseMachinerySpecs",
  UseVibrationAnalysis = "UseVibrationAnalysis",
  UseNodeBulkValveHealthReport = "UseNodeBulkValveHealthReport",
  UseCustomerMap = "UseCustomerMap",
  UseImportDataLayout = "UseImportDataLayout",
}

export const useFeatureEnabled = (featureFlag: FeatureFlagName): boolean => {
  const { featureFlags } = useFeatureFlagsContext();

  return featureFlags?.find((ff) => ff.featureName === featureFlag)?.isEnabled || false;
};

export interface EntityReferenceInput {
  id: number;
}

export interface LinkColumn {
  siteId: number;
  id: string;
  name: string;
  type: LinkColumnType;
}

export enum LinkColumnType {
  Asset = "Asset",
  Record = "Record",
  Application = "Application",
  Summary = "Summary",
  KpiTemplate = "KpiTemplate",
  RecordEvent = "RecordEvent",
  Area = "Area",
  Criticality = "Criticality",
  FailureMode = "FailureMode",
}

export type TimeUnit = "Hour" | "Day" | "Week" | "Month" | "Year";
export const TimeUnits: TimeUnit[] = ["Hour", "Day", "Month", "Year"];
export const LeadTimeUnits: TimeUnit[] = ["Day", "Week"];

export const parseTimeUnit = (value?: string | null) => {
  if (!!value) {
    const selected = TimeUnits.filter(
      (v) => v.toLowerCase() === value.toLowerCase() || pluralizeTimeUnit(v)?.toLowerCase() === value.toLowerCase()
    );
    if (selected.length > 0) {
      return selected[0];
    }
  }
  return null;
};

export const pluralizeTimeUnit = (value: TimeUnit | null) => (!!value ? `${value}s` : value || "");

export interface StringValueWithUsageCountViewModel {
  value: string;
  usageCount: number;
}

export interface IntValueLabelOption {
  label: string;
  value: number;
}

export interface ValueLabelOption {
  label: string;
  value: string;
}

export interface EnumValueOptions {
  AttachmentParentType: ValueLabelOption[];
  KpiReleaseType: ValueLabelOption[];
  KpiType: ValueLabelOption[];
  AssetType: ValueLabelOption[];
  FailureSeverity: ValueLabelOption[];
  FailureProbability: ValueLabelOption[];
  FailureDetectionAbility: ValueLabelOption[];
  RecordOutageDetailPhase: ValueLabelOption[];
  RecordOutageExecutionStatus: ValueLabelOption[];
  RecordOutageEvaluationScopeOutcome: ValueLabelOption[];
  RecordOutagePlanningScopeType: ValueLabelOption[];
  RecordOutagePlanningScopeLocation: ValueLabelOption[];
  SearchResultType: ValueLabelOption[];
  TimeUnit: ValueLabelOption[];
}

/** This can be regenerated by querying api/metadata/types */
export type KnownModelType =
  | "AssetDetailViewModel"
  | "HighRiskAssetViewModel"
  | "KpiLegacyValueModel"
  | "KpiOneTimeDowntimeAvoidanceValueModel"
  | "KpiOneTimeLabourOrEquipmentCostSavingsValueModel"
  | "KpiOneTimeReleaseOrSpillAvoidanceValueModel"
  | "KpiOngoingEmissionsReductionValueModel"
  | "KpiOngoingMaintenanceSavingsOrProductionImprovementValueModel"
  | "KpiValueDetailViewModel"
  | "KpiValueWithRecordDetailViewModel"
  | "RecommendationDetailViewModel"
  | "RecordDetailViewModel"
  | "RecordModel";

export interface MetaFieldDefinition {
  fieldName: string;
  field: string;
  specOptionType: string | null;
  muiGridColDef: MuiGridColDef | null;
}

export interface MuiGridColDef {
  field: string;
  headerName: string;
  type: string;
  valueOptions: null | ValueLabelOption[];
}

export type DataEntityType = "Asset" | "KpiValue" | "Recommendation" | "Record";
export interface QueryDataRequest {
  entityType: DataEntityType;
  siteId: number;
  skip?: number;
  take?: number;
  filters?: {
    items: GridFilterItem[];
    linkOperator: GridLinkOperator;
  };
  sort?: GridSortItem[];
}

export interface DataViewResponse<T> {
  results: T[];
  pageNumber: number;
  countOfAllResults: number;
  countOfAllPages: number;
}

export interface SubmitFeedbackRequest {
  feedbackCategory: string;
  content: string;
  currentUrl: string;
}

export type ThemeName = "light" | "dark";
