import * as React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { FormGroup, Label, Input, ModalFooter, Button } from "reactstrap";
import { useSiteIdFromRoute, useNotifications } from "app";
import classNames from "classnames";
import { KpiTemplateCommon, TemplateKpiType } from "./models";
import { SelectSingleTemplateKpiType } from "./formFields/SelectKpiType";
import { getValidity } from "common";
import { KpiHelpTooltip } from "./formFields/KpiHelpTooltip";
import { FunctionComponent, useState } from "react";
import { useCreateKpiTemplateRequest } from "./api";

interface Props {
  close: () => void;
  createdKpiTemplate: (template: KpiTemplateCommon, open: boolean) => void;
}

export const CreateKpiTemplateModal: FunctionComponent<Props> = (props) => {
  const siteId = useSiteIdFromRoute()!;
  const createKpiTemplateRequest = useCreateKpiTemplateRequest();
  const notifications = useNotifications();

  const [name, setName] = useState("");
  const nameValidity = getValidity(!!name.trim());
  const [kpiType, setKpiType] = useState<TemplateKpiType | null>(null);
  const kpiTypeValidity = getValidity(!!kpiType);
  const [showValidationErrors, setShowValidationErrors] = React.useState(false);

  const createKpiTemplate = (open: boolean) => {
    setShowValidationErrors(true);
    if (!nameValidity.valid || !kpiTypeValidity.valid) return;

    createKpiTemplateRequest
      .call({
        siteId,
        kpiType: kpiType!,
        name: name,
        enabled: true,
      })
      .then((kpiTemplate) => {
        if (!!kpiTemplate) {
          notifications.success(`Created ${name}`);
          props.createdKpiTemplate(kpiTemplate, open);
        }
      });
  };

  return (
    <Modal
      className="create-kpi-template-modal"
      isOpen={true}
      toggle={props.close}
      container={"#bootstrap-modal-container"}
    >
      <ModalHeader>Create a KPI template</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>KPI type</Label>
          <KpiHelpTooltip type="kpiType" />
          <SelectSingleTemplateKpiType
            className={classNames({ "is-invalid": showValidationErrors && !kpiTypeValidity.valid })}
            value={kpiType}
            onChange={(changed) => setKpiType(changed)}
          />
          <div className="invalid-feedback">Select a KPI type.</div>
        </FormGroup>

        <FormGroup>
          <Label>Name</Label>
          <Input
            className={classNames({ "is-invalid": showValidationErrors && !nameValidity.valid })}
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") createKpiTemplate(true);
            }}
            required={true}
          />
          <div className="invalid-feedback">{nameValidity.message}</div>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={() => createKpiTemplate(true)} disabled={createKpiTemplateRequest.loading}>
          Create template
        </Button>
        <Button color="secondary" onClick={props.close} disabled={createKpiTemplateRequest.loading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
