import { KpiTypeIcon } from "kpis/KpiTypeIcon";
import * as React from "react";
import Badge from "reactstrap/lib/Badge";
import { TemplateKpiType, toKpiTypeDiplayName } from "./models";

interface Props {
  kpiType: TemplateKpiType;
}

export class KpiTypeBadge extends React.PureComponent<Props> {
  public render() {
    const { kpiType } = this.props;
    return (
      <Badge className="kpiTypeBadge">
        <KpiTypeIcon kpiType={kpiType} />
        {toKpiTypeDiplayName(kpiType)}
      </Badge>
    );
  }
}
