import { TimeUnit } from "common";
import { KpiBase, KpiBaseFields } from "./KpiBase";
import { KpiPotentialDelayType } from "./KpiPotentialDelayType";

export interface KpiOneTimeScheduleDelayReduction extends KpiBase {
  reductionAmount: number | null;
  reductionTimeUnit: TimeUnit | null;
  potentialDelayType: KpiPotentialDelayType | string | null;
  location: string | null;
  actionsTaken: string | null;
}
export const KpiOneTimeScheduleDelayReductionFields: (keyof KpiOneTimeScheduleDelayReduction)[] = [
  ...KpiBaseFields,
  "reductionAmount",
  "reductionTimeUnit",
  "potentialDelayType",
  "location",
  "actionsTaken",
];

export class KpiOneTimeScheduleDelayReductionViewModel {
  model: KpiOneTimeScheduleDelayReduction;

  constructor(kpiValueDetail: KpiOneTimeScheduleDelayReduction) {
    this.model = kpiValueDetail;
  }
}
