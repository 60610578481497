import { FunctionComponent, useState } from "react";
import { Row, Col, Card, Button, Form, Label, Input, FormGroup } from "reactstrap";
import { useAutosaver, useNotifications, useUserContext } from "app";
import { getValidity } from "common";
import { useNavigate } from "react-router-dom";
import { KpiTemplateDetail, KpiTemplateModel, parseKpiId, TemplateKpiType } from "./models";
import { DeleteIcon } from "icons/DeleteIcon";
import { formatRoutePath, Routes } from "app";
import classNames from "classnames";
import Textarea from "react-textarea-autosize";
import { ConfirmationModal } from "common/ConfirmationModal";
import { KpiIcon } from "icons/KpiIcon";
import { KpiTypeBadge } from "./KpiTypeBadge";
import { EditKpiForm } from "./forms";
import { KpiSummary } from "./KpiSummary";
import { getKpiSignoffValidity, isKpiSignoffValidityValid, KpiSignoffForm } from "./forms/KpiSignoffForm";
import { SelectedKpiValues } from "common/SelectedKpiValues";
import { useDeleteKpiTemplateRequest, useUpdateKpiTemplateRequest } from "./api";

interface Props {
  kpiTemplate: KpiTemplateDetail;
}

export const KpiTemplateScreen: FunctionComponent<Props> = (props) => {
  const { userCanEditKpiTemplate, userCanUnlockKpi } = useUserContext().userPermissions;
  const notifications = useNotifications();
  const navigate = useNavigate();

  const updateKpiTemplateRequest = useUpdateKpiTemplateRequest();
  const [kpiTemplate, saveKpiTemplate] = useAutosaver(props.kpiTemplate.model, updateKpiTemplateRequest.call);
  const saveChanges = (changes: Partial<KpiTemplateModel>, now?: boolean) =>
    saveKpiTemplate({ ...kpiTemplate, ...changes }, now);

  const [deleting, setDeleting] = useState(false);
  const deleteKpiTemplateRequest = useDeleteKpiTemplateRequest();

  const [isKpiFormValid, setIsKpiFormValid] = useState(true);
  const [showFormValidationErrors, setShowFormValidationErrors] = useState(false);
  const [isSigningOff, setIsSigningOff] = useState(kpiTemplate.signedOff);
  const nameValidity = getValidity(!!kpiTemplate.name.trim());
  const kpiSignOffValidity = getKpiSignoffValidity(kpiTemplate, isSigningOff);

  return (
    <div className="kpi-screen">
      <title>SpartanPRO IRIS - {kpiTemplate.name}</title>
      <Row className="title-card-header">
        <Col md={8} lg={9} xl={9}>
          <Card body className="kpiTemplate-screen-title">
            <div className="screen-title">
              <div className="icon-and-title">
                <KpiIcon />
                <Form className="hover-borders">
                  <Textarea
                    className={classNames("form-control", {
                      "is-invalid": !nameValidity.valid,
                    })}
                    value={kpiTemplate.name}
                    onChange={(e) => saveChanges({ name: e.target.value })}
                    readOnly={!userCanEditKpiTemplate}
                  />
                  <div className="invalid-feedback">{nameValidity.message}</div>
                </Form>
              </div>
            </div>
            <div className="subtitle d-flex align-items-center mt-2">
              <KpiTypeBadge kpiType={props.kpiTemplate.kpiType as TemplateKpiType} />
              <div style={{ flexGrow: 1 }}></div>
              <KpiSummary kpi={props.kpiTemplate} />
              {!isKpiFormValid && !showFormValidationErrors && (
                <Button
                  style={{ marginLeft: "1em" }}
                  onClick={() => setShowFormValidationErrors(true)}
                  color="danger"
                  outline={true}
                  size="sm"
                >
                  Validate template
                </Button>
              )}
            </div>
          </Card>
        </Col>
        <Col md={4} lg={3} xl={3} className="d-flex justify-content-end align-items-start">
          <div className="screen-commands" style={{ marginRight: "1em" }}>
            <div style={{ margin: "2px 32px" }}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={!kpiTemplate.enabled}
                  onChange={() => saveChanges({ enabled: !kpiTemplate.enabled }, true)}
                  disabled={!userCanEditKpiTemplate}
                />
                Archived
              </Label>
            </div>
            {userCanEditKpiTemplate && (
              <>
                <Button color="danger" size="sm" onClick={() => setDeleting(true)}>
                  <DeleteIcon />
                  <span>Delete KPI template</span>
                </Button>
                <ConfirmationModal
                  title="Delete KPI?"
                  body={`Are you sure you want to delete this template?${
                    props.kpiTemplate.kpiValues.length === 0
                      ? ""
                      : ` The KPI will be removed from ${props.kpiTemplate.kpiValues.length} record(s).`
                  }`}
                  show={deleting}
                  cancel={() => setDeleting(false)}
                  confirm={async () => {
                    deleteKpiTemplateRequest.call(parseKpiId(props.kpiTemplate.id)).then(() => {
                      notifications.success("Template deleted.");
                      navigate(formatRoutePath(Routes.KpiTemplates, { siteId: kpiTemplate.siteId }));
                    });
                  }}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={9}>
          <Card body>
            <EditKpiForm
              model={kpiTemplate}
              detail={props.kpiTemplate}
              onUpdated={(formChanges, isFormValid) => {
                setIsKpiFormValid(isFormValid);
                saveChanges(formChanges);
              }}
              readOnly={!userCanEditKpiTemplate || kpiTemplate.signedOff}
              showValidationErrors={showFormValidationErrors}
              onLoaded={setIsKpiFormValid}
            >
              <>
                <Row>
                  <Col xs={12} className="spacer" style={{ margin: "1em 0" }}></Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6}>
                    <FormGroup>
                      <Label>Comments</Label>
                      <Textarea
                        className="form-control"
                        value={kpiTemplate.templateComments || ""}
                        onChange={(e) => {
                          saveChanges({
                            templateComments: e.target.value,
                          });
                        }}
                        readOnly={!userCanEditKpiTemplate}
                        minRows={3}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6}>
                    <KpiSignoffForm
                      showValidationErrors={showFormValidationErrors}
                      fields={kpiTemplate}
                      serverFields={props.kpiTemplate.model}
                      userCanUnlock={userCanUnlockKpi}
                      validity={kpiSignOffValidity}
                      readOnly={!userCanEditKpiTemplate}
                      saveValueChange={(changes) => {
                        saveChanges(changes);
                      }}
                      signOff={() => {
                        const kpiSignOffValidity = getKpiSignoffValidity(kpiTemplate, true);
                        setShowFormValidationErrors(true);
                        setIsSigningOff(true);
                        if (isKpiSignoffValidityValid(kpiSignOffValidity) && isKpiFormValid) {
                          saveChanges({ signedOff: true }, true);
                        } else {
                          notifications.error("The KPI is incomplete.");
                        }
                      }}
                      signOffRemoved={() => {
                        setShowFormValidationErrors(false);
                      }}
                    />
                  </Col>
                </Row>
              </>
            </EditKpiForm>
          </Card>
        </Col>
        <Col lg={3}>
          <SelectedKpiValues kpiValues={props.kpiTemplate.kpiValues} hideDetails={true} />
        </Col>
      </Row>
    </div>
  );
};
