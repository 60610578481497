import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { Button, Grid, Typography } from "@mui/material";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { EditKpiFormProps } from "./EditKpiFormProps";
import { SignedOffBadge } from "../signedOffBadge";
import { ControlledTextFieldFormItem } from "app/mui/forms/Input/ControlledTextFieldFormItem";

export const kpiFooterValidationSchema = yup.object({
  customerSignOff: yup.string().nullable().required("Required"),
  signOffDate: yup.date().nullable().required("Required"),
});

interface KpiFooterProps {
  userCanUnlock: boolean;
  signOff: () => void;
  removeSignOff: () => void;
  formItemProps: EditKpiFormProps;
  hasTemplate?: boolean;
}

function KpiFooter(props: KpiFooterProps) {
  const { userCanUnlock, signOff, removeSignOff, hasTemplate, formItemProps } = props;
  const [isSaving, setIsSaving] = useState(false);

  const methods = useFormContext();

  const isSignedOff = methods.watch("signedOff");

  const onSignOff = async () => {
    try {
      setIsSaving(true);
      await signOff();
    } finally {
      setIsSaving(false);
    }
  };
  const onRemoveSignOff = async () => {
    try {
      setIsSaving(true);
      await removeSignOff();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={12} md={7}>
        <Typography variant="h6">Sign-off</Typography>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} xl={true}>
            <ControlledTextFieldFormItem
              textFieldProps={{ placeholder: "Name", autoComplete: "none" }}
              label="Customer Contact"
              fieldName="customerSignOff"
              alwaysShowInput
              {...formItemProps}
            />
          </Grid>
          <Grid item xs={6} xl={true}>
            <DateInputFormItem label="Date" fieldName="signOffDate" alwaysShowInput {...formItemProps} />
          </Grid>
          {hasTemplate && isSignedOff && (
            <Grid item xs={6} xl="auto">
              <FormItemLayout label="">{() => <SignedOffBadge />}</FormItemLayout>
            </Grid>
          )}
          {!hasTemplate && (
            <Grid item xs={6} xl="auto">
              {isSignedOff === true ? (
                <FormItemLayout label="">
                  {() =>
                    userCanUnlock ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={onRemoveSignOff}
                        startIcon={<AssignmentTurnedInIcon />}
                        disabled={isSaving}
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        Remove&nbsp;Sign-off
                      </Button>
                    ) : (
                      <SignedOffBadge />
                    )
                  }
                </FormItemLayout>
              ) : (
                <FormItemLayout label="">
                  {() => (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={onSignOff}
                      startIcon={<AssignmentTurnedInIcon />}
                      disabled={isSaving}
                    >
                      Sign-off
                    </Button>
                  )}
                </FormItemLayout>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="h6">QBR</Typography>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} xl={7}>
            <ControlledTextFieldFormItem
              label=""
              fieldName="qbrNumber"
              textFieldProps={{ placeholder: "Number" }}
              alwaysShowInput
              {...formItemProps}
              readOnly={false}
            />
          </Grid>
          <Grid item xs={12} xl={5}>
            <DateInputFormItem label="Date" fieldName="qbrDate" alwaysShowInput {...formItemProps} readOnly={false} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default KpiFooter;
