import { Stack, Typography } from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

export type RecordsEventStatus = "Active" | "Archived";

interface IRecordsEventStatusBadgeProps {
  status: RecordsEventStatus;
}

export const RecordsEventStatusBadge = (props: IRecordsEventStatusBadgeProps) => {
  const { status } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {status === "Active" && <EventAvailableIcon />}
      {status === "Archived" && <Inventory2OutlinedIcon />}
      <Typography>{status}</Typography>
    </Stack>
  );
};
