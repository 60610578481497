import { Box, Paper, Stack, Typography } from "@mui/material";
import { Health } from "records";
import { HealthFormItem } from "app/mui/forms/Slider/HealthFormItem";
import { HealthTrafficLightBadge } from "assets/mui/health/HealthTrafficLightBadge";

interface VibrationHealthCardProps {
  health: Health;
}

export const VibrationHealthCard = (props: VibrationHealthCardProps) => {
  const { overall, overallCategory } = props.health;

  return (
    <Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" alignItems={"center"}>
        <Typography variant="h6">VIBRATION HEALTH</Typography>
      </Stack>
      <Box
        sx={{
          pt: 2,
          pb: 2,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            minHeight: "0",
            minWidth: "0",
            width: "100%",
            height: "100%",
            pt: 1,
          }}
        >
          <HealthTrafficLightBadge overall={overall} overallCategory={overallCategory} />
          <Stack
            sx={{
              width: "100%",
              pt: 1,
              pr: 1,
            }}
            spacing={2}
            direction="column"
          >
            <HealthFormItem
              fieldName="health.vibration"
              syncFieldName="health.vibration"
            />
          </Stack>
        </Stack>
      </Box>
    </Paper >
  );
};
