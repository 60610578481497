import React from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { KnownModelType } from "common";
import { useSiteContext } from "app/contexts";
import { GetActions } from "./models";
import { useGeneratedColumns } from "./useGeneratedColumns";
import { Site } from "sites";

interface MetadataColumnProviderProps {
  type: KnownModelType;
  sites?: Site[];
  getActions?: GetActions;
  children: (columns: GridColDef[]) => React.ReactNode;
}

export const MetadataColumnProvider = (props: MetadataColumnProviderProps) => {
  const siteContext = useSiteContext().currentSite!;

  const { columns, loading } = useGeneratedColumns({
    type: props.type,
    sites: props.sites ?? [siteContext],
    getActions: props.getActions,
  });

  return <>{loading ? null : props.children(columns)}</>;
};
