import { TimeUnit } from "common";
import { KpiBase, KpiBaseFields } from "./KpiBase";
import { KpiCommodityUnit } from "./CommodityUnits";
import { KpiPotentialDelayType } from "./KpiPotentialDelayType";
import { UseFormWatch } from "react-hook-form";

export interface KpiOneTimeDowntimeAvoidance extends KpiBase {
  potentialDelayType: KpiPotentialDelayType | string | null;
  location: string | null;
  actionsTaken: string | null;
  commodityProductionRate: number | null;
  commodityUnit: KpiCommodityUnit | null;
  commodityType: string | null;
  commodityProductionRateTimeUnit: TimeUnit | null;
  commodityPricePerUnit: number | null;
  potentialDowntime: number | null;
  potentialDowntimeTimeUnit: TimeUnit | null;
  probabilityOfDowntime: number | null;
  isSimplifiedVersion: boolean;
  migratedKpiLegacyId: number | null;
  simplifiedPotentialCost: number | null;
  simplifiedActualCost: number | null;
}

export const KpiOneTimeDowntimeAvoidanceFields: (keyof KpiOneTimeDowntimeAvoidance)[] = [
  ...KpiBaseFields,
  "potentialDelayType",
  "location",
  "actionsTaken",
  "commodityProductionRate",
  "commodityUnit",
  "commodityType",
  "commodityProductionRateTimeUnit",
  "commodityPricePerUnit",
  "potentialDowntime",
  "potentialDowntimeTimeUnit",
  "probabilityOfDowntime",
  "isSimplifiedVersion",
  "migratedKpiLegacyId",
  "simplifiedPotentialCost",
  "simplifiedActualCost",
];

export class KpiOneTimeDowntimeAvoidanceViewModel {
  model: KpiOneTimeDowntimeAvoidance;
  watch: UseFormWatch<Partial<KpiOneTimeDowntimeAvoidance>>;

  constructor(kpiValueDetail: KpiOneTimeDowntimeAvoidance, watch: UseFormWatch<Partial<KpiOneTimeDowntimeAvoidance>>) {
    this.model = kpiValueDetail;
    this.watch = watch;
  }

  get isSimplifiedVersionValue(): boolean {
    return this.watch("isSimplifiedVersion") ?? false;
  }

  get isSimplifiedVersionLockedValue(): boolean {
    return this.watch("migratedKpiLegacyId") != null;
  }
}
