import { createTheme } from "@mui/material";

export const IrisColors = {
  blueSpartan: "#004B8D",
  graySpartan: "#939598",
  dark: "#343A40",
  softDark: "#575B60",
  black: "#000000",
  blue: "#0068C3",
  background: "#EFEDEA",
  white: "#FFFFFF",
  borders: "#E7E7E7",
  green: "#28A745",
  gray100: "#f8f8f8",
  gray200: "#EEEEEE",
  gray300: "#DFDFDF",
  gray500: "#A8A8A8",
  gray600: "#808080",
  textLinks: "#004B8D",
  textLinksHover: "#0068C3",
  yellow: "#FFC107",
  orange: "#FD7E14",
  red: "#E53E3E",
  pink: "#f7dddd",
  purple: "#6B46C1",
  legacyTertiaryGray: "#505354",
  legacyGray100: "#f8f9fa",
  legacyGray200: "#e9ecef",
  legacyGray300: "#dee2e6",
  legacyGray400: "#ced4da",
  legacyGray450: "#b5b5b5",
  legacyGray500: "#adb5bd",
  legacyGray600: "#6c757d",
  legacyGray700: "#495057",
  legacyGray800: "#343a40",
  legacyGray900: "#212529",
  legacyIndigo: "#6610f2",
  legacyPurple: "#6f42c1",
  legacyPink: "#e83e8c",
  legacyRed: "#dc3545",
  legacyTeal: "#20c997",
  legacyCyan: "#17a2b8",
  legacyBrown: "#684326",
};

export const systemFonts =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
export const montserratFonts = "Montserrat, " + systemFonts;

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: IrisColors.dark,
      light: "#cbcbcb",
      dark: "#777777",
      contrastText: "#fff",
    },
    secondary: {
      main: IrisColors.blueSpartan,
    },
    background: {
      paper: IrisColors.white,
      default: IrisColors.background,
    },
    text: {
      primary: IrisColors.dark,
      secondary: "#666666",
      disabled: IrisColors.dark,
    },
    divider: IrisColors.borders,
    defaultChip: {
      main: IrisColors.legacyGray600,
      light: IrisColors.legacyGray300,
      dark: IrisColors.legacyGray800,
      contrastText: "#fff",
    },
    AssetHealth_Good: {
      main: IrisColors.green,
      contrastText: "#fff",
    },
    AssetHealth_MinorIssues: {
      main: IrisColors.yellow,
      contrastText: IrisColors.dark,
    },
    AssetHealth_ModerateIssues: {
      main: IrisColors.orange,
      contrastText: "#fff",
    },
    AssetHealth_MajorIssues: {
      main: IrisColors.red,
      contrastText: "#fff",
    },
    AssetHealth_Unknown: {
      main: IrisColors.gray500,
      contrastText: "#fff",
    },
    Yellow: {
      main: IrisColors.yellow,
      contrastText: IrisColors.dark,
      light: IrisColors.yellow,
      dark: IrisColors.yellow,
    },
  },
  typography: {
    // Perferring system fonts improves performance and the fonts just look nicer. A lot of front end of
    // frameworks are moving in this direction anyway.
    //
    // https://medium.com/needmore-notes/using-system-fonts-for-web-apps-bf76d214a0e0
    fontFamily: systemFonts,
    fontSize: 14,
    h1: {
      fontFamily: montserratFonts,
    },
    h2: {
      fontFamily: montserratFonts,
    },
    h3: {
      fontFamily: montserratFonts,
    },
    h4: {
      fontFamily: montserratFonts,
    },
    h5: {
      fontFamily: montserratFonts,
    },
    h6: {
      fontFamily: systemFonts,
      fontWeight: "bold",
    },
    montserrat: {
      fontFamily: montserratFonts,
      fontWeight: 300,
    },
    montserratBold: {
      fontFamily: montserratFonts,
      fontWeight: 700,
    },
  },
  mixins: {
    toolbar: {
      background: IrisColors.white,
    },
  },
  components: {
    MuiChip: {
      defaultProps: { color: "defaultChip", sx: { fontWeight: "bold" } },
    },
    MuiToggleButtonGroup: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            "& .MuiToggleButton-root.MuiToggleButtonGroup-grouped": {
              borderColor: IrisColors.blueSpartan,
              color: IrisColors.blueSpartan,
              backgroundColor: IrisColors.white,
              "&:hover": {
                backgroundOpacity: 0.25,
                backgroundColor: "#e2f0fc",
              },
            },
            "& .MuiToggleButtonGroup-grouped.Mui-selected": {
              color: IrisColors.white,
              backgroundColor: "#01315B",
              "&:hover": {
                backgroundColor: "#01315B",
              },
            },
          },
        },
        {
          props: { color: "secondary" },
          style: {
            "& .MuiToggleButton-root.MuiToggleButtonGroup-grouped": {
              borderColor: IrisColors.blueSpartan,
              color: IrisColors.blueSpartan,
              backgroundColor: IrisColors.white,
              "&:hover": {
                backgroundColor: "#e2f0fc",
              },
              "&.Mui-disabled": {
                borderColor: IrisColors.gray300,
                backgroundColor: IrisColors.gray100,
                color: IrisColors.gray500,
              },
            },
            "& .MuiToggleButtonGroup-grouped.Mui-selected": {
              color: "#024D8E",
              backgroundColor: "#c2e1fc",
              "&:hover": {
                backgroundColor: "#c2e1fc",
              },
              "&.Mui-disabled": {
                borderColor: IrisColors.gray300,
                backgroundColor: IrisColors.gray300,
                color: IrisColors.dark,
              },
            },
          },
        },
      ],
    },
  },
});

// Adds a new color to the palette and makse typescript happy
// https://mui.com/material-ui/customization/palette/#adding-new-colors
declare module "@mui/material/styles" {
  interface Palette {
    defaultChip: Palette["primary"];
    AssetHealth_Good: Palette["primary"];
    AssetHealth_MinorIssues: Palette["primary"];
    AssetHealth_ModerateIssues: Palette["primary"];
    AssetHealth_MajorIssues: Palette["primary"];
    AssetHealth_Unknown: Palette["primary"];
    Yellow: Palette["warning"];
  }

  interface PaletteColor {
    defaultChip?: string;
    AssetHealth_Good?: string;
    AssetHealth_MinorIssues?: string;
    AssetHealth_ModerateIssues?: string;
    AssetHealth_MajorIssues?: string;
    AssetHealth_Unknown?: string;
    Yellow?: string;
  }

  interface PaletteOptions {
    defaultChip: PaletteOptions["primary"];
    AssetHealth_Good: PaletteOptions["primary"];
    AssetHealth_MinorIssues: PaletteOptions["primary"];
    AssetHealth_ModerateIssues: PaletteOptions["primary"];
    AssetHealth_MajorIssues: PaletteOptions["primary"];
    AssetHealth_Unknown: PaletteOptions["primary"];
    Yellow: Palette["warning"];
  }

  interface TypographyVariants {
    montserrat: React.CSSProperties;
    montserratBold: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    montserrat?: React.CSSProperties;
    montserratBold?: React.CSSProperties;
  }
}

declare module "@mui/material/Badge" {
  interface BadgePropsColorOverrides {
    defaultChip: true;
    AssetHealth_Good: true;
    AssetHealth_MinorIssues: true;
    AssetHealth_ModerateIssues: true;
    AssetHealth_MajorIssues: true;
    AssetHealth_Unknown: true;
    Yellow: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    defaultChip: true;
    AssetHealth_Good: true;
    AssetHealth_MinorIssues: true;
    AssetHealth_ModerateIssues: true;
    AssetHealth_MajorIssues: true;
    AssetHealth_Unknown: true;
    Yellow: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    montserrat: true;
    montserratBold: true;
  }
}
