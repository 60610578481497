import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface EditFieldDialogProps extends BaseDialogProps {
  fieldName: string;
  currentValue: string;
  methods: UseFormReturn<any>;
  saveChanges: () => void;
  dialogTitle: string;
  label: string;
  confirmButtonText: string;
}

const validationSchema = yup.object({
  fieldValue: yup.string().nullable().required("required"),
});

export const EditFieldDialog = (props: EditFieldDialogProps) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { fieldValue: props.currentValue },
  });

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      if (values.fieldValue.trim() !== props.methods.getValues(props.fieldName).trim()) {
        props.methods.setValue(props.fieldName, values.fieldValue.trim());
        props.saveChanges();
      }
      props.onClose();
    })();
  };

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
        {props.dialogTitle}
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <TextFieldFormItem
              {...methods.register("fieldValue")}
              label={props.label}
              fieldName="fieldValue"
              textFieldProps={{ autoFocus: true }}
            />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            {props.confirmButtonText}
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
