import {
  darken,
  lighten,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { CurrentSiteWithNavigation } from "app";
import { useUiThemeContext } from "app/contexts/UiThemeContext";
import { AssetType } from "assets/models/assetType";
import { AssetIcon } from "icons/AssetIcon";
import { useState } from "react";
import { Link } from "react-router-dom";
import { theme } from "../theme";

interface AssetsMenuButtonProps {
  active: boolean;
  currentSiteWithNavigation: CurrentSiteWithNavigation;
  expanded: boolean;
}

function createAssetTypeRoute(currentSiteWithNavigation, assetType: AssetType) {
  return currentSiteWithNavigation.routeTo.TypeAssets({ assetType: assetType });
}

export const AssetsMenuButton = ({ currentSiteWithNavigation: siteNav, expanded, active }: AssetsMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isDarkTheme = useUiThemeContext().navbar === "dark";
  const bgColor = isDarkTheme ? theme.palette.primary.main : theme.palette.background.paper;
  const fillColor = isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main;

  const label = "Assets";

  const allAssetsMatch = false;
  const controlValveMatch = false;
  if (!siteNav) {
    return null;
  }

  return (
    <>
      <Tooltip title={expanded ? "" : label} placement="right">
        <ListItem key={label} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              px: "12px",
              height: 48,
              justifyContent: "initial",
              color: active ? bgColor : fillColor,
              background: active ? fillColor : bgColor,
              "&:hover": {
                background: active ? fillColor : darken(bgColor, 0.1),
              },
            }}
            component={"a"}
            onClick={handleOpenMenu}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                width: "24px",
                svg: {
                  fill: active ? bgColor : fillColor,
                },
              }}
            >
              <AssetIcon width="100%" height="100%" />
            </ListItemIcon>
            <ListItemText
              primary={label}
              sx={{ opacity: expanded ? 1 : 0, px: 2, transition: "opacity 0.3s", whiteSpace: "nowrap" }}
            />
          </ListItemButton>
        </ListItem>
      </Tooltip>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        anchorReference="anchorEl"
        anchorOrigin={{
          vertical: -8,
          horizontal: expanded ? 230 : 60,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiMenu-paper": {
            background: bgColor,
            padding: "10px",
          },
        }}
      >
        <AssetPageLink
          handleClose={handleClose}
          label="All Assets"
          link={siteNav.routeTo.Assets()}
          active={allAssetsMatch}
        />
        <AssetPageLink
          handleClose={handleClose}
          label="Control Valve"
          link={createAssetTypeRoute(siteNav, AssetType.ControlValve)}
          active={controlValveMatch}
        />
        <AssetPageLink
          handleClose={handleClose}
          label="Isolation Valve"
          link={createAssetTypeRoute(siteNav, AssetType.IsolationValve)}
          active={controlValveMatch}
        />
        <AssetPageLink
          handleClose={handleClose}
          label="Manual Valve"
          link={createAssetTypeRoute(siteNav, AssetType.ManualValve)}
          active={controlValveMatch}
        />
        <AssetPageLink
          handleClose={handleClose}
          label="Relief Valve"
          link={createAssetTypeRoute(siteNav, AssetType.ReliefValve)}
          active={controlValveMatch}
        />
        <AssetPageLink
          handleClose={handleClose}
          label="Regulator"
          link={createAssetTypeRoute(siteNav, AssetType.Regulator)}
          active={controlValveMatch}
        />
        <AssetPageLink
          handleClose={handleClose}
          label="Measurement"
          link={createAssetTypeRoute(siteNav, AssetType.Measurement)}
          active={controlValveMatch}
        />
        <AssetPageLink
          handleClose={handleClose}
          label="Machinery"
          link={createAssetTypeRoute(siteNav, AssetType.Machinery)}
          active={controlValveMatch}
        />
        <AssetPageLink
          handleClose={handleClose}
          label="Tank"
          link={createAssetTypeRoute(siteNav, AssetType.Tank)}
          active={controlValveMatch}
        />
        <AssetPageLink
          handleClose={handleClose}
          label="Motor Operated Valve"
          link={createAssetTypeRoute(siteNav, AssetType.MotorOperatedValve)}
          active={controlValveMatch}
        />
        <AssetPageLink
          handleClose={handleClose}
          label="Steam Trap"
          link={createAssetTypeRoute(siteNav, AssetType.SteamTrap)}
          active={controlValveMatch}
        />
      </Menu>
    </>
  );
};

const AssetPageLink = (props: { label: string; link: string; handleClose: () => void; active: boolean }) => {
  const isDarkTheme = useUiThemeContext().navbar === "dark";
  const bgColor = isDarkTheme ? theme.palette.primary.main : theme.palette.background.paper;
  const fillColor = isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main;

  return (
    <MenuItem
      onClick={props.handleClose}
      component={Link}
      to={props.link}
      sx={{
        px: "12px",
        height: 48,
        justifyContent: "initial",
        color: props.active ? bgColor : fillColor,
        background: props.active ? fillColor : bgColor,
        borderRadius: "5px",
        margin: "2px",
        "&:hover": {
          background: lighten(bgColor, 0.2),
        },
      }}
    >
      {props.label}
    </MenuItem>
  );
};
