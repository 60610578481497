import { Button, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TabsApi } from "../useTabbedViews";
import { useModal } from "app/mui/common/useModal";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ReplayIcon from "@mui/icons-material/Replay";
import { FormProvider, useForm } from "react-hook-form";
import { usePopover } from "app/mui/common/usePopover";
import { CustomDataViewScopes, getCustomDataViewScopeOptions } from "./models";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useSiteContext } from "app/contexts";
import { ShareWindowsIcon } from "icons/ShareWindowsIcon";
import { IrisColors } from "app/mui/theme";

interface MoreOptionsTabViewButtonProps {
  tabsApi: TabsApi;
}

export function MoreOptionsTabViewButton(props: MoreOptionsTabViewButtonProps) {
  const { openPopover, popoverProps, closePopover } = usePopover();
  const { openModal: openEditModal, modalProps: editModalProps } = useModal();
  const { openModal: openDeleteModal, modalProps: deleteModalProps } = useModal();
  const { openModal: openShareModal, modalProps: shareModalProps } = useModal();

  const buttonShowing = props.tabsApi.canSaveView;

  if (!buttonShowing) {
    return null;
  }

  const reset = () => {
    props.tabsApi.resetTableState();
    closePopover();
  };

  const openEdit = () => {
    openEditModal();
    closePopover();
  };

  const openShare = () => {
    openShareModal();
    closePopover();
  };

  const openDelete = () => {
    openDeleteModal();
    closePopover();
  };

  return (
    <>
      <Button variant="text" size="small" onClick={openPopover} endIcon={<ArrowDropDownIcon />}>
        <MoreHorizIcon />
      </Button>
      <Popover
        {...popoverProps}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList>
          <MenuItem onClick={reset} disabled={!props.tabsApi.isDirty}>
            <ListItemIcon>
              <ReplayIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Reset</ListItemText>
          </MenuItem>
          <MenuItem onClick={openShare}>
            <ListItemIcon>
              <ShareWindowsIcon fill={IrisColors.gray600} />
            </ListItemIcon>
            <ListItemText>Share view</ListItemText>
          </MenuItem>
          <MenuItem onClick={openEdit} disabled={!props.tabsApi.canSaveView}>
            <ListItemIcon>
              <EditRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit view</ListItemText>
          </MenuItem>
          <MenuItem onClick={openDelete} disabled={!props.tabsApi.canSaveView}>
            <ListItemIcon>
              <DeleteOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete view</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
      <EditModal tabsApi={props.tabsApi} {...editModalProps} />
      <DeleteModal tabsApi={props.tabsApi} {...deleteModalProps} />
      {shareModalProps.open && <ShareModal tabsApi={props.tabsApi} {...shareModalProps} />}
    </>
  );
}

interface EditModalProps {
  open: boolean;
  onClose: VoidFunction;
  tabsApi: TabsApi;
}

export const EditModal = (props: EditModalProps) => {
  const { currentSite } = useSiteContext();

  const appliesToOptions = currentSite ? getCustomDataViewScopeOptions(currentSite) : [];

  const currentView = props.tabsApi.currentView;

  // prettier-ignore
  const initialScope =
    currentView?.siteId == null && currentView?.customerId == null ? CustomDataViewScopes.Everywhere
      : currentView?.siteId == null && currentView?.customerId != null ? CustomDataViewScopes.ThisCustomer
        : currentView?.siteId != null && currentView?.customerId == null ? CustomDataViewScopes.ThisSite
          : null;

  const methods = useForm({ defaultValues: { name: currentView?.name, scope: initialScope } });

  const onSubmit = () => {
    methods.handleSubmit(async (values) => {
      await props.tabsApi.editCurrentView({
        name: values.name ?? "",
        scope: values.scope ?? CustomDataViewScopes.Everywhere,
      });

      props.onClose();
    })();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <DialogTitle>Edit View</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextFieldFormItem label="Name" fieldName="name" textFieldProps={{ autoFocus: true }} />

            {currentSite && (
              <AutocompleteFormItem
                label="Applies to"
                options={appliesToOptions}
                fieldName={"scope"}
                autocompleteProps={{ disableClearable: true, disablePortal: false }}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={onSubmit}>Edit View</Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

interface DeleteModalProps {
  open: boolean;
  onClose: VoidFunction;
  tabsApi: TabsApi;
}

export const DeleteModal = (props: DeleteModalProps) => {
  const onConfirmDelete = async () => {
    await props.tabsApi.deleteCurrentView();
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Delete this view?</DialogTitle>
      <DialogContent>
        <Typography variant="body2">This action cannot be undone.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={onConfirmDelete} color="error">
          Delete View
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ShareModalProps {
  open: boolean;
  onClose: VoidFunction;
  tabsApi: TabsApi;
}

export const ShareModal = (props: ShareModalProps) => {
  const encodedState = props.tabsApi.exportView();
  const url = new URL(window.location.origin);
  url.pathname = window.location.pathname;
  url.searchParams.set("import", encodedState);

  const methods = useForm({ defaultValues: { url } });

  return (
    <Dialog fullWidth maxWidth="xs" open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <DialogTitle>Share View</DialogTitle>
        <DialogContent dividers={true} >
          <Stack padding={"6px"}>
            <Typography >Copy sharable link</Typography>
          </Stack>
          <Stack spacing={2} direction="row">
            <TextFieldFormItem fieldName="url" textFieldProps={{ InputProps: { disabled: true } }} />
            <Button
              variant={"outlined"}
              onClick={() => {
                navigator.clipboard.writeText(url.toString());
              }}
            >
              <ContentPasteIcon />
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Done</Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
