import React, { useState } from "react";
import { Box, Paper, Tabs, Tab } from "@mui/material";
import { TabPanel } from "app/mui/tabs/TabPanel";
import { AttachmentsArea } from "attachments/mui/AttachmentsArea";
import { SummaryDetail } from "summaries/models";

interface SummaryAttachmentProps {
  summaryDetail: SummaryDetail;
}

export const SummaryTabSections: React.FC<SummaryAttachmentProps> = ({ summaryDetail }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const summary = summaryDetail.model;

  const handleChangeTab = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, px: 2 }}>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          <Tab label="Attachments" />
          <Tab label="Assets" />
          <Tab label="Records" />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0} sx={{ p: 2 }}>
        {!!summary.id && <AttachmentsArea parentType="Summary" parentId={summary.id} parentTag={summary.title} />}
      </TabPanel>
      <TabPanel value={tabIndex} index={1} sx={{ p: 2 }}></TabPanel>
      <TabPanel value={tabIndex} index={2} sx={{ p: 2 }}></TabPanel>
    </Paper>
  );
};
