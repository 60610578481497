import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect } from "react";
import _ from "lodash";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { KpiTypeIcon } from "kpis/KpiTypeIcon";
import { TemplateKpiTypes, getKpiTypeOption } from "kpis/models";
import { KPITemplatesLoader } from "app/mui/KpiTemplatesLoader";
import { useGetKpiValueDetailsByRecordRequest, useCreateKpiValuesRequest } from "kpis";

interface AddRecordKpiDialogProps extends BaseDialogProps {
  recordId: number;
  siteId: number;
}

const validationSchema = yup.object({
  kpiType: yup.object().nullable().required("Required"),
});

export const AddRecordKpiDialog = (props: AddRecordKpiDialogProps) => {
  const { recordId, siteId, onClose } = props;

  const methods = useForm({ resolver: yupResolver(validationSchema) });

  const kpiValuesQuery = useGetKpiValueDetailsByRecordRequest(recordId);
  const createKpiValuesRequest = useCreateKpiValuesRequest();

  const kpiValues = kpiValuesQuery.data || [];
  const kpiType = methods.watch("kpiType");

  const usedTemplateIds = _.uniq(
    kpiValues
      .filter((k) => k.kpiType === kpiType?.value && !!k.model.kpiTemplateId)
      .filter((k) => !!k.model.kpiTemplateId)
      .map((k) => k.model.kpiTemplateId!)
  );

  useEffect(() => {
    if (kpiValuesQuery.loading) {
      return;
    }

    methods.setValue("kpiType", getKpiTypeOption("OneTimeLabourOrEquipmentCostSavings"));
  }, [kpiValuesQuery.loading]);

  useEffect(() => {
    methods.setValue("kpiTemplateId", "");
  }, [kpiType]);

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      createKpiValuesRequest
        .call({
          siteId: siteId,
          recordId: recordId,
          kpiType: values.kpiType.value,
          kpiTemplateId: values.kpiTemplateId,
        })
        .then(() => {
          onClose();
        });
    })();
  };

  return (
    <BaseDialog onClose={onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Add KPI
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <AutocompleteFormItem
              label="KPI type"
              fieldName="kpiType"
              options={TemplateKpiTypes.map((item) => getKpiTypeOption(item))}
              autocompleteProps={{
                disablePortal: false,
                isOptionEqualToValue: (option, value) => option.value === value.value,
                groupBy: (option) => option.category,
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} sx={{ "& > svg": { mr: 2, flexShrink: 0 } }}>
                  <KpiTypeIcon kpiType={option.value} />
                  <span>{option.label}</span>
                </Box>
              )}
            />
            <KPITemplatesLoader kpiType={kpiType} usedTemplateIds={usedTemplateIds}>
              {(options) => (
                <AutocompleteFormItem
                  label="Template"
                  fieldName="kpiTemplateId"
                  options={options.options}
                  autocompleteProps={{
                    disablePortal: false,
                    isOptionEqualToValue: (option, value) => option.id === value.id,
                  }}
                />
              )}
            </KPITemplatesLoader>
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            Add
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
