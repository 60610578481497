import { Paper, Skeleton, Stack, Typography } from "@mui/material";

export const EntityCardSkeleton = () => {
	return (
		<Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
			<Stack justifyContent="space-between" direction="row">
				<Typography variant="h6" width="75%">
					<Skeleton />
				</Typography>
			</Stack>
		</Paper>
	);
};
