import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import { KpiTemplateStatusOptions, KpiClientTemplateFilters } from "./models";
import { RecordTypeBadge, RecordTypes } from "../records";
import classNames from "classnames";
import { SelectMultiTemplateKpiType } from "./formFields/SelectKpiType";

interface Props {
  filters: KpiClientTemplateFilters;
  updateFilters: (filters: KpiClientTemplateFilters) => void;
  nameFragment: string;
}

export const KpiTemplateFiltersDisplay: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="kpi-template-filters filters">
      <div className="filter-row">
        <FormGroup>
          <Label>Name (may be partial)</Label>
          <Input
            value={props.filters.nameFragment ?? ""}
            onChange={(e) => {
              props.updateFilters({ ...props.filters, nameFragment: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup className="double-width">
          <Label>KPI types</Label>
          <SelectMultiTemplateKpiType
            value={props.filters.kpiTypes}
            onChange={(kpiTypes) => {
              props.updateFilters({
                ...props.filters,
                kpiTypes: kpiTypes,
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Record types</Label>
          <Select
            isMulti
            className={classNames("react-select", "hide-cursor")}
            classNamePrefix="react-select"
            value={props.filters.recordTypes.map((type) => ({ value: type }))}
            onChange={(selections) => {
              const recordTypes = selections.map((selection) => selection.value);
              props.updateFilters({
                ...props.filters,
                recordTypes: recordTypes,
              });
            }}
            formatOptionLabel={(recordType) => <RecordTypeBadge type={recordType.value} />}
            getOptionValue={(option) => option.value}
            options={RecordTypes.map((rt) => ({ value: rt }))}
          />
        </FormGroup>
        <FormGroup>
          <Label>Status</Label>
          <Select
            className={classNames("react-select", "hide-cursor")}
            classNamePrefix="react-select"
            value={{ value: props.filters.enabled }}
            onChange={(selection) => {
              props.updateFilters({
                ...props.filters,
                enabled: selection!.value,
              });
            }}
            formatOptionLabel={(option) => option.value}
            getOptionValue={(option) => option.value}
            options={KpiTemplateStatusOptions.map((v) => ({ value: v }))}
          />
        </FormGroup>
      </div>
    </div>
  );
};
