import { AssetType } from "assets/models/assetType";
import _, { camelCase } from "lodash";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import { useMatch, useNavigate } from "react-router-dom";
import { useGetSiteDigestsRequest } from "sites/api";

export const customersIdSegment = "/customers/:customerId";
export const sitesIdSegment = "/sites/:siteId";

export interface RouteParamWithSiteId {
  siteId: string;
}

export interface RouteParamWithSiteIdAndId extends RouteParamWithSiteId {
  id: string;
}

export interface RouteParamWithSiteIdKpiTypeAndRowId extends RouteParamWithSiteId {
  kpiType: string;
  rowId: string;
}

export const Routes = {
  Home: "/",
  Tutorials: "/tutorials",
  Customers: "/customers",
  CustomerDashboard: customersIdSegment,
  ManageKeywords: "/manage-keywords",
  Sites: "/sites",
  /* Site Scoped */
  SiteDashboard: sitesIdSegment,
  Application: `${sitesIdSegment}/applications/:id`,
  Applications: `${sitesIdSegment}/applications`,
  Asset: `${sitesIdSegment}/assets/:id`,
  Assets: `${sitesIdSegment}/assets`,
  TypeAssets: `${sitesIdSegment}/assets/:assetType`,
  ImportData: `${sitesIdSegment}/import-data`,
  ImportCsv: `${sitesIdSegment}/import-csv`,
  ImportOffline: `${sitesIdSegment}/import-offline`,
  KpiTemplates: `${sitesIdSegment}/kpi-templates`,
  KpiTemplate: `${sitesIdSegment}/kpi-templates/:id`,
  Record: `${sitesIdSegment}/records/:id`,
  RecordLegacy: `${sitesIdSegment}/records/:id/legacy`,
  RecordsEvent: `${sitesIdSegment}/records-events/:id`,
  RecordsEvents: `${sitesIdSegment}/records-events`,
  RecordsEventsLegacy: `${sitesIdSegment}/records-events/legacy`,
  Summary: `${sitesIdSegment}/summaries/:id`,
  Summaries: `${sitesIdSegment}/summaries`,
  SummariesLegacy: `${sitesIdSegment}/summaries/legacy`,

  Tasks: `${sitesIdSegment}/tasks`,
  Records: `${sitesIdSegment}/tasks/records`,
  Recommendations: `${sitesIdSegment}/tasks/recommendations`,
  Kpis: `${sitesIdSegment}/tasks/kpis`,
};

// Routes to which a user can be redirected when changing sites
const crossSiteRoutes = [
  Routes.Applications,
  Routes.Assets,
  Routes.ImportData,
  Routes.ImportCsv,
  Routes.ImportOffline,
  Routes.KpiTemplates,
  Routes.Records,
  Routes.RecordsEvents,
  Routes.Summaries,
  Routes.Tasks,
  Routes.Recommendations,
  Routes.Kpis,
];

/** returns the route path to which the site change ought to go.
 * For example, if the user is currently at "/sites/1/assets/3", provided the siteId 2, it will return "/sites/2/assets"
 */
export const getCrossSiteRoutePath = (siteId: number, currentPath: string) => {
  for (var i = 0; i < crossSiteRoutes.length; i++) {
    const regex = new RegExp(crossSiteRoutes[i].replace(":siteId", "\\d+"));
    if (regex.test(currentPath)) {
      return formatRoutePath(crossSiteRoutes[i], { siteId });
    }
  }
  return formatRoutePath(Routes.SiteDashboard, { siteId });
};

export interface RoutingTokens {
  siteId: number;
  id?: number | string;
  assetType?: AssetType;
}

export interface CustomerRoutingTokens {
  customerId: number;
}

export const formatSiteRoutePath = (routeTemplate: string, tokens: RoutingTokens) => {
  return routeTemplate
    .replace(":siteId", tokens.siteId?.toString() || "")
    .replace(":id", tokens.id?.toString() || "")
    .replace(":assetType", camelCase(tokens.assetType) || "");
};

export const formatRoutePath = formatSiteRoutePath;

export const formatCustomerRoutePath = (routeTemplate: string, tokens: CustomerRoutingTokens) => {
  return routeTemplate.replace(":customerId", tokens.customerId?.toString() || "");
};

const numericRegexp = /^\d+$/;

const useCustomerIdFromRouteRetriever = () => {
  const routeMatch = useMatch({ path: customersIdSegment, end: false });

  const isCustomerRoute = !!routeMatch?.params?.customerId;
  const customerId =
    isCustomerRoute && numericRegexp.test(routeMatch?.params?.customerId ?? "0")
      ? parseInt(routeMatch?.params?.customerId ?? "0")
      : null;

  return { customerId, isCustomerRoute };
};

export const useCustomerIdFromRouteWithHistory = () => {
  const retreived = useCustomerIdFromRouteRetriever();
  const navigate = useNavigate();
  if (retreived.isCustomerRoute && !retreived.customerId) {
    navigate(Routes.Sites);
    return 0;
  }
  return retreived.customerId;
};

export const useCustomerIdFromRoute = () => {
  return useCustomerIdFromRouteWithHistory();
};

const useSiteIdFromRouteRetriever = () => {
  const routeMatch = useMatch({ path: sitesIdSegment, end: false });

  const isSiteRoute = !!routeMatch?.params?.siteId;
  const siteId =
    isSiteRoute && numericRegexp.test(routeMatch?.params?.siteId ?? "0")
      ? parseInt(routeMatch?.params?.siteId ?? "0")
      : null;

  return { siteId, isSiteRoute };
};

export const useSiteIdFromRouteWithHistory = () => {
  const retreived = useSiteIdFromRouteRetriever();
  const navigate = useNavigate();
  if (retreived.isSiteRoute && !retreived.siteId) {
    navigate(Routes.Sites);
    return 0;
  }
  return retreived.siteId;
};

export const useSiteIdFromRoute = () => {
  return useSiteIdFromRouteWithHistory();
};

export const useDefaultCustomerSite = (customerId: number | null) => {
  const siteDigestsRequest = useGetSiteDigestsRequest();

  const loading = siteDigestsRequest.loading;
  const siteDigests = siteDigestsRequest.data || [];

  const firstCustomer = loading
    ? null
    : _.first(
      _.orderBy(
        _.uniqBy(siteDigests, (s) => s.customer.id)
          .map((s) => s.customer)
          .filter((c) => !customerId || customerId === c.id),
        (c) => c.name?.toLowerCase() ?? ""
      )
    );

  const customerSites = !!firstCustomer ? siteDigests.filter((s) => s.customerId === firstCustomer.id) : [];
  return { loading, customerSites };
};

/** Returns the default landing page for the first customer, or the one specified by the customer id */
export const useGetDefaultLandingPageForCustomer = (customerId: number | null) => {
  const { loading, customerSites } = useDefaultCustomerSite(customerId);
  const url =
    customerSites.length === 0
      ? null
      : customerSites.length === 1
        ? formatSiteRoutePath(Routes.SiteDashboard, { siteId: customerSites[0].id })
        : formatCustomerRoutePath(Routes.CustomerDashboard, { customerId: customerSites[0].customerId });

  return { loading, url };
};

export const useValidatedSiteIdFromRoute = (fallbackRoute: string) => {
  const navigate = useNavigate();
  const { siteId } = useSiteIdFromRouteRetriever();

  let { id } = useParams();

  const parsedId = id && numericRegexp.test(id) ? parseInt(id) : null;

  if (parsedId == null) {
    if (siteId) {
      navigate(formatRoutePath(fallbackRoute, { siteId }));
    } else {
      navigate(Routes.Sites);
    }
    return 0;
  }

  return parsedId;
};

export const useMatchesRoutes = (...paths: string[]) => {
  const location = useLocation();

  const matches = matchRoutes(
    paths.map((path) => ({ path })),
    location
  );

  return matches && matches.length > 0;
};
