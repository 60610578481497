import { Box, Grid, Typography } from "@mui/material";
import CurrencyFormItem from "app/mui/forms/Input/CurrencyFormItem";
import { formatCurrency } from "common/utilities";
import { EffectiveRateTooltip } from "kpis/formFields/EffectiveRateTooltip";
import { KpiHelpTooltip } from "kpis/formFields/KpiHelpTooltip";
import { KpiOneTimeLabourOrEquipmentCostSavings, KpiOneTimeLabourOrEquipmentCostSavingsViewModel } from "kpis/models";
import { useMemo } from "react";
import { EditKpiFormProps } from "../EditKpiFormProps";
import { KpiGridStyles } from "../KpiGridStyles";
import { PotentialEquipmentCostFormItem } from "../formItems/PotentialEquipmentCostFormItem";
import { PotentialLabourCostFormItem } from "../formItems/PotentialLabourCostFormItem";
import { RemoteHoursFormItem } from "../formItems/RemoteHoursFormItem";
import SavingsCategoryFormItem from "../formItems/SavingsCategoryFormItem";
import SelectedApproachFormItem from "../formItems/SelectedApproachFormItem";
import { SitePersonnelLabourFormItem } from "../formItems/SitePersonnelLabourFormItem";
import { RadioYesNoFormItem } from "../../../../app/mui/forms/Radio/RadioYesNoFormItem";
import { SpecialistLabourFormItem } from "../formItems/SpecialistLabourFormItem";
import KpiDivider from "../sections/KpiDivider";
import { useFormContext } from "react-hook-form";
import { SwitchFormItem } from "app/mui/forms/Toggle/SwitchFormItem";
import { KpiCommentsFormItem } from "../formItems/KpiCommentsFormItem";

interface KpiOneTimeLabourOrEquipmentCostSavingsDetailsProps extends EditKpiFormProps {}

export function KpiOneTimeLabourOrEquipmentCostSavingsDetails(
  props: KpiOneTimeLabourOrEquipmentCostSavingsDetailsProps
) {
  const { watch } = useFormContext();
  const kpi = useMemo(
    () =>
      new KpiOneTimeLabourOrEquipmentCostSavingsViewModel(
        props.detail.model as KpiOneTimeLabourOrEquipmentCostSavings,
        watch
      ),
    [props.detail.model, watch]
  );

  const isKpiTemplate = !!!props.record;
  const hasTemplateComments = !!props.model.kpiTemplateId && !!props.model.templateComments;

  const {
    primaryDividerSubtitleProps,
    primaryDividerTitleProps,
    secondaryDividerTitleProps,
    gridContainerProps,
    kpiSectionProps,
    gridRowProps,
  } = KpiGridStyles;

  const inputProps = useMemo(
    () => ({
      readOnly: props.readOnly,
    }),
    [props.readOnly]
  );

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Grid container {...gridContainerProps} rowSpacing={0}>
          <Grid item xs={12}>
            <Box width="min-content">
              <SwitchFormItem
                label="Simplified&nbsp;Version"
                fieldName="isSimplifiedVersion"
                {...inputProps}
                readOnly={kpi.isSimplifiedVersionLockedValue || inputProps.readOnly}
                tip={
                  <KpiHelpTooltip
                    type={kpi.isSimplifiedVersionLockedValue ? "simplifiedViewLocked" : "simplifiedView"}
                  />
                }
              />
            </Box>
          </Grid>
        </Grid>
        {kpi.isSimplifiedVersionValue && (
          <>
            <Grid container {...gridContainerProps}>
              <Grid item xs={12} {...gridRowProps}>
                <KpiDivider
                  left={<Typography {...primaryDividerTitleProps}>Actual costs</Typography>}
                  right={
                    <CurrencyFormItem
                      fieldName="simplifiedActualCost"
                      label={undefined}
                      numberFormatInputProps={{ placeholder: "0" }}
                      {...inputProps}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} {...gridRowProps}>
                <KpiDivider
                  left={<Typography {...primaryDividerTitleProps}>Potential costs</Typography>}
                  right={
                    <CurrencyFormItem
                      fieldName="simplifiedPotentialCost"
                      label={undefined}
                      numberFormatInputProps={{ placeholder: "0" }}
                      {...inputProps}
                    />
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
        {!kpi.isSimplifiedVersionValue && (
          <>
            <Grid container {...gridContainerProps} rowSpacing={0}>
              <Grid item xs={12} {...gridRowProps}>
                <Typography variant="h6">KPI approach</Typography>
              </Grid>
              <Grid item xs={6}>
                <SelectedApproachFormItem
                  label=""
                  multiplierFieldName="multiplier"
                  multiplyEquipmentFieldName="multiplyEquipment"
                  multiplyLabourFieldName="multiplyLabour"
                  tip={<KpiHelpTooltip type="kpiApproach" />}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={6}>
                <SavingsCategoryFormItem
                  fieldName="savingsCategory"
                  tip={<KpiHelpTooltip type="savingsCategory" />}
                  {...inputProps}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} {...kpiSectionProps}>
              <KpiDivider
                left={<Typography {...primaryDividerTitleProps}>Actual costs</Typography>}
                right={<Typography {...primaryDividerTitleProps}>{formatCurrency(kpi.actualCosts, true)}</Typography>}
              />
              <Typography {...primaryDividerSubtitleProps}>Technology-driven approach</Typography>
            </Grid>
            <Grid item xs={12} mt={2}>
              <KpiDivider
                dividerSize="small"
                left={<Typography {...secondaryDividerTitleProps}>Labour</Typography>}
                right={
                  <Typography {...secondaryDividerTitleProps}>{formatCurrency(kpi.actualLabourCost, true)}</Typography>
                }
              />
            </Grid>
            <KpiDivider
              left={
                <Grid container {...gridContainerProps} {...gridRowProps}>
                  <Grid item xs={12} md={6}>
                    <SpecialistLabourFormItem
                      fieldName="spartanHours"
                      hourlyRate={kpi.spartanRatePerHourFieldValue ?? 0}
                      {...inputProps}
                      tip={
                        <EffectiveRateTooltip
                          isKpiTemplate={isKpiTemplate}
                          rate={kpi.spartanRatePerHourFieldValue ?? 0}
                          rateEffectiveDate={kpi.model.rateEffectiveDate}
                          rateEffectiveDateType={kpi.model.rateEffectiveDateType}
                          rateType="Specialist"
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RemoteHoursFormItem
                      fieldName="remoteHours"
                      hoursFieldName="spartanHours"
                      tip={<KpiHelpTooltip type="remoteHours" />}
                      {...inputProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RadioYesNoFormItem
                      fieldName="dedicatedTripsAvoided"
                      label="Site visit avoided"
                      tip={<KpiHelpTooltip type="dedicatedTripsAvoided" />}
                      {...inputProps}
                    />
                  </Grid>
                </Grid>
              }
              right={
                <Typography {...secondaryDividerTitleProps}>
                  {formatCurrency(kpi.specialistLabourCost ?? 0, true)}
                </Typography>
              }
            />
            <Grid container {...gridContainerProps} {...gridRowProps}>
              <Grid item xs={12}>
                <KpiDivider
                  dividerSize="small"
                  left={
                    <SitePersonnelLabourFormItem
                      label="Site personnel labour"
                      fieldName="customerHours"
                      hourlyRate={kpi.customerRatePerHourFieldValue ?? 0}
                      tip={
                        <EffectiveRateTooltip
                          isKpiTemplate={isKpiTemplate}
                          rate={kpi.customerRatePerHourFieldValue ?? 0}
                          rateEffectiveDate={kpi.model.rateEffectiveDate}
                          rateEffectiveDateType={kpi.model.rateEffectiveDateType}
                          rateType="Site personnel"
                        />
                      }
                      {...inputProps}
                    />
                  }
                  right={
                    <Typography {...secondaryDividerTitleProps}>
                      {formatCurrency(kpi.sitePersonnelLabourCost ?? 0, true)}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2} {...gridRowProps}>
              <KpiDivider
                dividerSize="small"
                left={<Typography {...secondaryDividerTitleProps}>Equipment</Typography>}
                right={
                  <Typography {...secondaryDividerTitleProps}>
                    {formatCurrency(kpi.actualEquipmentCostFieldValue, true)}
                  </Typography>
                }
              />
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <CurrencyFormItem fieldName="actualEquipmentCost" {...inputProps} />
              </Grid>
            </Grid>
            <Grid item xs={12} {...kpiSectionProps}>
              <KpiDivider
                left={<Typography {...primaryDividerTitleProps}>Potential costs</Typography>}
                right={
                  <Typography {...primaryDividerTitleProps}>{formatCurrency(kpi.potentialCosts ?? 0, true)}</Typography>
                }
              />
              <Typography {...primaryDividerSubtitleProps}>Traditional approach</Typography>
            </Grid>
            <Grid item xs={12} {...kpiSectionProps}>
              <KpiDivider
                dividerSize="small"
                left={
                  <>
                    <Typography {...secondaryDividerTitleProps}>Labour</Typography>
                    <PotentialLabourCostFormItem
                      fieldName="potentialHours"
                      ratePerHour={kpi.customerRatePerHourFieldValue ?? 0}
                      multiply={kpi.multiplyLabourFieldValue || false}
                      multiplier={kpi.multiplierFieldValue || 0}
                      combinedActualHours={kpi.combinedActualHours}
                      tip={<KpiHelpTooltip type="oneTimeLabourPotentialLabour" />}
                      {...inputProps}
                    />
                  </>
                }
                right={
                  <Typography {...primaryDividerTitleProps}>
                    {formatCurrency(kpi.potentialLabourCost ?? 0, true)}
                  </Typography>
                }
              />
              <Typography {...primaryDividerSubtitleProps}>Traditional approach</Typography>
            </Grid>
            <Grid item xs={12} {...kpiSectionProps}>
              <KpiDivider
                dividerSize="small"
                left={
                  <>
                    <Typography {...secondaryDividerTitleProps}>Equipment</Typography>
                    <PotentialEquipmentCostFormItem
                      label={undefined}
                      fieldName="potentialEquipmentCost"
                      multiply={kpi.multiplyEquipmentFieldValue || false}
                      multiplier={kpi.multiplierFieldValue || 0}
                      actualEquipmentCost={kpi.actualEquipmentCostFieldValue || 0}
                      {...inputProps}
                    />
                  </>
                }
                right={
                  <Typography {...primaryDividerTitleProps}>
                    {formatCurrency(kpi.potentialEquipmentCost ?? 0, true)}
                  </Typography>
                }
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={2}>
          {hasTemplateComments && (
            <Grid item xs={12} >
              <KpiCommentsFormItem
                label="Comments" 
                fieldName="templateComments"
                alwaysShowInput
                readOnly={true} 
              />
            </Grid>
          )}
          <Grid item xs={12} >
            <KpiCommentsFormItem
              label="Additional Comments" 
              fieldName="comments" 
              readOnly={!props.userCanEdit} 
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
