import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import {
  gridFilteredSortedRowEntriesSelector,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { useSiteContext } from "app";
import { ActionDispatcherProvider, useActionDispatcher, useActionDispatcherContext } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { BusinessAreaContextProvider, useBusinessArea } from "app/mui/BusinessAreaContext";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { ToolbarWithTabViews } from "app/mui/tables/ToolbarWithTabViews";
import {
  createStaticActions,
  createStaticRowActions,
  useStaticActions,
  useStaticRowActions,
} from "app/mui/tables/utils/createStaticColumns";
import { useGetFilteredAssetsRequest } from "assets/api";
import { dateFormat } from "common";
import { AssetIcon } from "icons/AssetIcon";
import { SummaryIcon } from "icons/SummaryIcon";
import { useDownloadCsvExportRequest } from "importCsv/api";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { CreateAssetDialog } from "./dialogs/CreateAsset";
import { CreateAssetSummaryDialog } from "./dialogs/CreateAssetSummary";
import { CreateRecordDialog } from "./dialogs/CreateRecord";
import { DuplicateDialog } from "./dialogs/Duplicate";
import { GenerateReportDialog } from "./dialogs/GenerateReport";
import { AssetListDataViz } from "./visualizations/AssetListDataViz";
import { AssetDigest, AssetFilters } from "assets/models/asset";
import { AssetDetailView } from "assets/models/assetDetail";
import { useExportAssetAttachmentsRequest } from "attachments/api";

const pageActions = createStaticActions((actionDispatcher, userPermissions) => [
  {
    icon: <AssetIcon />,
    label: "Create asset",
    action: () => actionDispatcher.dispatch("CreateAsset"),
    hidden: !userPermissions.userCanAddAssets,
  },
  {
    icon: <SummaryIcon />,
    label: "Create summary",
    action: () => actionDispatcher.dispatch("CreateAssetSummary"),
    hidden: !userPermissions.userCanEditSummary,
  },
  {
    icon: <DownloadIcon />,
    label: "Download editable CSV",
    action: () => actionDispatcher.dispatch("DownloadCSV"),
    hidden: !userPermissions.userCanExportAssetList,
  },
  {
    icon: <DownloadIcon />,
    label: "Export current view",
    action: () => actionDispatcher.dispatch("ExportCurrentViewCSV"),
    hidden: !userPermissions.userCanExportAssetList,
  },
  {
    icon: <DownloadIcon />,
    label: "Bulk attachment export",
    action: () => actionDispatcher.dispatch("BulkAttachmentExport"),
    hidden: !userPermissions.userCanExportAssetAttachment,
  },
]);

export const AssetsListPage = () => {
  const actionDispatcher = useActionDispatcher();

  const actions = useStaticActions(pageActions, actionDispatcher);

  const apiRef = useGridApiRef();

  return (
    <ActionDispatcherProvider actionDispatcher={actionDispatcher}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              svg: {
                fill: (theme) => theme.palette.primary.main,
                width: "28px",
                height: "28px",
              },
            }}
          >
            <AssetIcon />
            <Typography variant="h5" fontWeight="bold">
              Assets
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <AssetListDataViz apiRef={apiRef} />
            <ActionsDropdownMenu primary actions={actions} />
          </Stack>
          <BusinessAreaContextProvider>
            <FilteredAssetsTable apiRef={apiRef} />
          </BusinessAreaContextProvider>
        </Stack>
      </Paper>
    </ActionDispatcherProvider>
  );
};

const rowActions = createStaticRowActions<AssetDetailView>((actionDispatcher, userPermissions) => (params) => [
  {
    icon: <CreateNewFolderRoundedIcon />,
    label: "Create Record",
    onClick: () => {
      actionDispatcher.dispatch("CreateRecord", params);
    },
    showInMenu: true,
    hidden: !userPermissions.userCanEditRecords,
  },
  {
    icon: <AssessmentRoundedIcon />,
    label: "Generate Report",
    onClick: () => {
      actionDispatcher.dispatch("GenerateReport", params);
    },
    showInMenu: true,
    hidden: !userPermissions.userCanGenerateAssetReport,
  },
  {
    icon: <ContentCopyRoundedIcon />,
    label: "Duplicate",
    onClick: () => {
      actionDispatcher.dispatch("Duplicate", params);
    },
    showInMenu: true,
    hidden: !userPermissions.userCanEditAssets,
    disabled: !userPermissions.userCanEditAsset(params.row.model.assetType),
  },
]);

interface FilteredAssetsTableProps {
  apiRef: React.MutableRefObject<GridApiPro>;
}

const GridComponents = {
  LoadingOverlay: LinearProgress,
  Toolbar: ToolbarWithTabViews("Assets", { includeUserDefinedTabs: true, includeBusinessAreaFilter: true }),
};

const FilteredAssetsTable = ({ apiRef }: FilteredAssetsTableProps) => {
  const { businessArea } = useBusinessArea();

  const actionDispatcher = useActionDispatcherContext();
  const site = useSiteContext().currentSite!;
  const siteId = site.id;
  const assetsRequest = useGetFilteredAssetsRequest(siteId);
  const assetDetails = assetsRequest.data ?? [];

  const downloadCsvExportRequest = useDownloadCsvExportRequest(site, "Assets");
  const exportAssetAttachmentsRequest = useExportAssetAttachmentsRequest(site);

  const [assetInDialog, setAssetInDialog] = React.useState<AssetDetailView | null>(null);
  const [openDialog, setOpenDialog] = React.useState<
    "createAsset" | "createAssetSummary" | "createRecord" | "generateReport" | "duplicate" | null
  >(null);

  const [assetsForSummary, setAssetsForSummary] = useState<AssetDigest[]>([]);

  const getTableRowActions = useStaticRowActions(rowActions, actionDispatcher);

  const handleAction = useCallback(
    (action: string, params: any) => {
      if (action === "DownloadCSV") {
        const ids = gridFilteredSortedRowIdsSelector(apiRef);
        downloadCsvExportRequest.call(ids.map((id) => Number(id)));
      }
      if (action === "ExportCurrentViewCSV") {
        apiRef.current.exportDataAsCsv({
          fileName: `${site.name} Asset CurrentView ${moment().format(dateFormat)}`,
          utf8WithBom: true,
        });
      }
      if (action === "BulkAttachmentExport") {
        const ids = gridFilteredSortedRowIdsSelector(apiRef);
        exportAssetAttachmentsRequest.call(ids.map((id) => Number(id)));
      }

      if (action === "CreateAsset") {
        setOpenDialog("createAsset");
      }
      if (action === "CreateAssetSummary") {
        setOpenDialog("createAssetSummary");
        const rows = gridFilteredSortedRowEntriesSelector(apiRef);
        setAssetsForSummary(rows.map((r) => r.model) as AssetDigest[]);
      }

      if (action === "CreateRecord") {
        setAssetInDialog(params.row);
        setOpenDialog("createRecord");
      }
      if (action === "GenerateReport") {
        setAssetInDialog(params.row);
        setOpenDialog("generateReport");
      }
      if (action === "Duplicate") {
        setAssetInDialog(params.row);
        setOpenDialog("duplicate");
      }
    },
    [downloadCsvExportRequest]
  );

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    const filters = new AssetFilters();
    filters.businessArea = businessArea?.toString() ?? null;
    assetsRequest.call(filters);
  }, [businessArea]);

  const closeDialog = () => {
    setOpenDialog(null);
    setAssetInDialog(null);
  };

  return (
    <>
      <Box sx={{ height: "700px", width: "100%" }}>
        <MetadataColumnProvider type="AssetDetailViewModel" getActions={getTableRowActions}>
          {(columns) => (
            <StripedDataGrid
              apiRef={apiRef}
              density="compact"
              loading={assetsRequest.loading}
              components={GridComponents}
              columns={columns}
              rows={assetDetails}
            />
          )}
        </MetadataColumnProvider>
      </Box>

      {assetInDialog && (
        <>
          {openDialog === "createRecord" && <CreateRecordDialog onClose={closeDialog} asset={assetInDialog.model} />}
          {openDialog === "generateReport" && (
            <GenerateReportDialog onClose={closeDialog} asset={assetInDialog.model} />
          )}
          {openDialog === "duplicate" && <DuplicateDialog onClose={closeDialog} asset={assetInDialog.model} />}
        </>
      )}
      {openDialog === "createAsset" && <CreateAssetDialog onClose={closeDialog} />}
      {openDialog === "createAssetSummary" && (
        <CreateAssetSummaryDialog onClose={closeDialog} assets={assetsForSummary} />
      )}
    </>
  );
};
