import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { IrisColors } from "app/mui/theme";

export const SignedOffBadge = () => {
  const theme = useTheme();
  return (
    <Box
      component="div"
      sx={{
        display: "inline-block",
        verticalAlign: "middle",
        color: theme.palette.primary.main,
        padding: theme.spacing(1),
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.button.fontWeight,
        textTransform: theme.typography.button.textTransform,
      }}
    >
      <Stack direction="row" sx={{ borderWidth: "2px", borderColor: IrisColors.gray300 }} spacing={1}>
        <AssignmentTurnedInIcon />
        <Typography>Signed Off</Typography>
      </Stack>
    </Box>
  );
};
