import * as React from "react";
import { formatRoutePath, Routes, useUserContext } from "app";
import { Button, Card, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSiteIdFromRoute } from "app";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { formatSortCaret } from "common";
import { KpiIcon } from "../icons/KpiIcon";
import { CreateKpiTemplateModal } from "kpis/CreateKpiTemplateModal";
import { KpiTemplateCommon, useGetFilteredKpiTemplateDetailsInSiteRequest } from "kpis";
import {
  KpiClientTemplateFilters,
  KpiTemplateDetail,
  KpiTemplateRecordTypeUsage,
  TemplateKpiType,
  toKpiTypeDiplayName,
} from "./models";
import { KpiTemplateFiltersDisplay } from "./KpiTemplateFiltersDisplay";
import NumberFormat from "react-number-format";
import moment from "moment";
import { dateFormat } from "common/formats";
import { useEffect, useState } from "react";
import DoubleScrollbar from "react-double-scrollbar";
import _ from "lodash";

const getFilteredKpiTemplates = (
  kpiTemplates: KpiTemplateDetail[],
  filters: KpiClientTemplateFilters
): KpiTemplateDetail[] => {
  const regex = filters.nameFragment.length > 0 ? new RegExp(filters.nameFragment, "i") : null;
  return kpiTemplates.filter((t) => {
    if (filters.enabled === "Archived") {
      if (t.model.enabled) return false;
    } else if (filters.enabled === "Active") {
      if (!t.model.enabled) return false;
    }

    if (filters.kpiTypes.length > 0) {
      if (!filters.kpiTypes.includes(t.kpiType as TemplateKpiType)) return false;
    }

    if (!!regex && !t.model.name.match(regex)) {
      return false;
    }

    if (filters.recordTypes.length > 0) {
      if (
        _.intersection(
          filters.recordTypes,
          t.recordTypeUsages.map((rt) => rt.recordType)
        ).length === 0
      ) {
        return false;
      }
    }

    return true;
  });
};

export const KpiTemplateList: React.FunctionComponent<{}> = (props) => {
  const navigate = useNavigate();
  const siteId = useSiteIdFromRoute()!;
  const { userCanEditKpiTemplate } = useUserContext().userPermissions;
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [createKpiTemplateModalOpen, setCreateKpiTemplateModalOpen] = useState(false);
  const [filters, setFilters] = useState(new KpiClientTemplateFilters());

  const query = useGetFilteredKpiTemplateDetailsInSiteRequest(siteId);
  useEffect(() => {
    query.call({ siteId });
  }, []);
  const kpiTemplates = getFilteredKpiTemplates(query.data ?? [], filters);

  const updateItemsPerPage = (value: string, onSizePerPageChange: (size: number) => void) => {
    const itemsPerPage = value === "" ? 0 : parseInt(value);

    setItemsPerPage(itemsPerPage);
    onSizePerPageChange(itemsPerPage);
  };

  const toggleCreateKpiTemplateModal = () => {
    setCreateKpiTemplateModalOpen(!createKpiTemplateModalOpen);
  };

  const createdKpiTemplate = (newKpiTemplate: KpiTemplateCommon) => {
    toggleCreateKpiTemplateModal();
    navigate(formatRoutePath(Routes.KpiTemplate, newKpiTemplate));
  };

  const getDataColumns = () => {
    const nameColumn = {
      dataField: "model.name",
      text: "Name",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: string, kpiTemplate: KpiTemplateDetail) => (
        <Link key={kpiTemplate.id} to={formatRoutePath(Routes.KpiTemplate, kpiTemplate)}>
          {value}
        </Link>
      ),
      headerClasses: "xl-column",
    };

    const typeColumn = {
      dataField: "kpiType",
      text: "Type",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: TemplateKpiType, kpiTemplate: KpiTemplateDetail) => toKpiTypeDiplayName(value),
      headerClasses: "xl-column",
    };

    const enabledColumn = {
      dataField: "model.enabled",
      text: "Status",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: boolean, kpiTemplate: KpiTemplateDetail) => (value ? "Active" : "Archived"),
      headerClasses: "sm-column",
    };

    const signedOffColumn = {
      dataField: "model.signedOff",
      text: "Signed off",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: boolean, kpiTemplate: KpiTemplateDetail) => (!!value ? "Yes" : "No"),
      headerClasses: "sm-column",
    };

    const savingsColumn = {
      dataField: "calculatedSavings",
      text: "Savings",
      sort: true,
      align: () => "right",
      headerAlign: () => "right",
      sortCaret: formatSortCaret,
      formatter: (value: number, kpiTemplate: KpiTemplateDetail) => (
        <NumberFormat
          value={value}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          prefix={kpiTemplate.calculatedSavingsUnit?.startsWith("Dollars") ? "$" : ""}
        />
      ),
      headerClasses: "md-column",
    };

    const savingsUnitColumn = {
      dataField: "calculatedSavingsUnit",
      text: "Savings unit",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: string, kpiTemplate: KpiTemplateDetail) => value,
      headerClasses: "lg-column",
    };

    const roiColumn = {
      dataField: "calculatedReturnOnInvestment",
      text: "ROI",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: string, kpiTemplate: KpiTemplateDetail) => value,
      headerClasses: "xs-column",
    };

    const createdOnColumn = {
      dataField: "created",
      text: "Created",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: string, kpiTemplate: KpiTemplateDetail) => moment(value).format(dateFormat),
      headerClasses: "xs-column",
    };

    const lastAssignedOnColumn = {
      dataField: "lastAssignedOn",
      text: "Last Assigned",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: Date | null, kpiTemplate: KpiTemplateDetail) =>
        !value ? "-" : moment(value).format(dateFormat),
      headerClasses: "sm-column",
    };

    const recordTypesColumn = {
      dataField: "recordTypeUsages",
      text: "Assigned Records",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: KpiTemplateRecordTypeUsage[], kpiTemplate: KpiTemplateDetail) => {
        if (!value) return 0;
        return _.sum(value.map((v) => v.numberOfRecords));
      },
      headerClasses: "xl-column",
    };

    return [
      nameColumn,
      typeColumn,
      enabledColumn,
      signedOffColumn,
      savingsColumn,
      savingsUnitColumn,
      roiColumn,
      createdOnColumn,
      lastAssignedOnColumn,
      recordTypesColumn,
    ];
  };

  return (
    <div className="legacy_bootstrap">
      <ToolkitProvider keyField="id" data={kpiTemplates} columns={getDataColumns()}>
        {(toolkitProps: any) => (
          <Card body className="kpi-template-list">
            <div className="screen-title">
              <div className="icon-and-title">
                <KpiIcon />
                <div>KPI Templates</div>
              </div>
              <div className="screen-commands">
                {userCanEditKpiTemplate && (
                  <Button color="secondary" onClick={() => toggleCreateKpiTemplateModal()}>
                    Create KPI Template
                  </Button>
                )}
              </div>
            </div>

            <KpiTemplateFiltersDisplay
              filters={filters}
              updateFilters={(newFilters) => {
                setFilters(newFilters);
              }}
              nameFragment={filters.nameFragment}
            />
            <div className="stacking-context">
              <DoubleScrollbar>
                <div className="pagination-top">
                  <BootstrapTable
                    {...toolkitProps.baseProps}
                    classes="table-sm list-table"
                    remote={{ filter: true }}
                    loading={query.loading}
                    bootstrap4={true}
                    striped
                    bordered={false}
                    defaultSorted={[{ dataField: "title", order: "asc" }]}
                    defaultSortDirection="asc"
                    headerClass="header"
                    pagination={paginationFactory({
                      sizePerPage: itemsPerPage,
                      hidePageListOnlyOnePage: true,
                      sizePerPageRenderer: (props: {
                        options: any;
                        currSizePerPage: number;
                        onSizePerPageChange: (size: number) => void;
                      }) => {
                        return (
                          <div className="items-per-page">
                            <label>Templates per page</label>
                            <Input
                              type="number"
                              value={itemsPerPage}
                              onChange={(e) => updateItemsPerPage(e.target.value, props.onSizePerPageChange)}
                            />
                            <label style={{ paddingLeft: "1em" }}>Templates: {kpiTemplates.length}</label>
                          </div>
                        );
                      },
                      hideSizePerPage: false,
                    })}
                    noDataIndication={() =>
                      query.loading ? <div>Loading...</div> : <div>No KPI templates found.</div>
                    }
                  />
                </div>
              </DoubleScrollbar>
            </div>
            {userCanEditKpiTemplate && createKpiTemplateModalOpen && (
              <CreateKpiTemplateModal
                key="kpi-template-list-create-kpi-template-modal"
                close={toggleCreateKpiTemplateModal}
                createdKpiTemplate={createdKpiTemplate}
              />
            )}
          </Card>
        )}
      </ToolkitProvider>
    </div>
  );
};
