import { ActionDispatcherApi } from "app/mui/ActionDispatcher";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";

import { SummaryDetail } from "summaries/models";

import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { AddSummaryIcon } from "icons/AddSummaryIcon";
import { useCallback, useEffect, useState } from "react";
import { DeleteSummaryDialog } from "./DeleteSummaryDialog";

type DialogActionsType = "deleteSummary" | "createSummary" | null;
const validDialogActions = ["deleteSummary", "createSummary"];

interface SummaryListActionsProps {
  actionDispatcher: ActionDispatcherApi;
  onRefetch: () => void;
}

export const SummaryListActions = (props: SummaryListActionsProps) => {
  const { onRefetch, actionDispatcher } = props;
  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);
  const [summaryForDialog, setSummaryForDialog] = useState<SummaryDetail>();

  const closeDialog = () => setOpenDialog(null);

  const pageActions = createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <AddSummaryIcon />,
      label: "Create simmary",
      action: () => actionDispatcher.dispatch("createSummary"),
      hidden: !userPermissions.userCanEditSummary,
    },
  ]);

  const actions = useStaticActions(pageActions, actionDispatcher);

  const handleAction = useCallback((action: string, params: SummaryDetail) => {
    if (validDialogActions.indexOf(action) > -1) {
      if (action === "deleteSummary") {
        setSummaryForDialog(params);
      }

      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  return (
    <>
      <ActionsDropdownMenu primary actions={actions} />
      {openDialog === "deleteSummary" && (
        <DeleteSummaryDialog
          onClose={closeDialog}
          summaryDetail={summaryForDialog!}
          onDeleteSummary={() => {
            onRefetch();
            closeDialog();
          }}
        />
      )}
    </>
  );
};
